import Buefy from 'buefy';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import App from './App.vue';
import config from './config';
import { messages } from './constants/messages';
import ErrorHandler from './plugins/errorHandler';
import router from './router';

Vue.use(Buefy);
Vue.use(VueI18n);
Vue.use(ErrorHandler);

Vue.use(Buefy);

Vue.config.productionTip = false;

Vue.prototype.$config = config;

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
});

new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
