<template>
  <div class="map-legend-control">
    <b-button
      v-if="!show"
      class="is-pulled-right"
      @click="$emit('update:show', true)"
    >
      {{ $t('LEGEND') }}
    </b-button>

    <aside
      v-if="show"
      class="map-legend box"
    >
      <b-button
        icon-left="minus-circle"
        size="is-medium"
        alt="Minimize map legend"
        title="Minimize map legend"
        class="close-button is-pulled-right"
        type="is-ghost"
        aria-label="Minimize map legend"
        @keypress.enter="$emit('update:show', false)"
        @click.native="$emit('update:show', false)"
      />
      <div class="legend-group">
        <h2 class="title is-5 mb-1">
          {{ $t('FEATURE_TYPE') }}
        </h2>
        <ul class="legend-list">
          <li
            v-for="type in featureTypes"
            :key="type.label"
            :class="type.class"
          >
            <b-icon
              class="type-icon"
              :style="{
                color: type.color,
              }"
              pack="mdi"
              :icon="type.icon"
            />
            <span>{{ type.label }}</span>
          </li>
        </ul>
      </div>

      <div class="legend-group">
        <h2 class="title is-5 mb-1">
          {{ $t('PASSABILITY_STATUS') }}
        </h2>
        <ul class="legend-list">
          <li
            v-for="status in passabilityStatuses"
            :key="status.label"
          >
            <div
              class="status-icon"
              :style="{
                background: (accessibilityMode) ? '#fff' : status.color,
                'width': (accessibilityMode) ? '1.5em' : '0.5em',
              }"
              v-text="(accessibilityMode) ? status.symbolText : ' '"
            />
            <span>{{ status.label }}</span>
          </li>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
import scssVars from '@/assets/scss/variables.module.scss';

export default {
    name: 'MapLegend',

    props: {
        show: {
            type: Boolean,
            default: true,
        },

        accessibilityMode: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        this.$i18n.locale = this.$route.params.locale;
        return {
            featureTypes: [
                {
                    label: this.$t('DAM'),
                    icon: 'square-outline',
                },
                {
                    label: this.$t('WATERFALL'),
                    icon: 'triangle-outline',
                },
                {
                    label: this.$t('FISHWAY'),
                    icon: 'fish',
                },
                {
                    label: this.$t('MODELLED_CROSSINGS'),
                    icon: 'circle-outline',
                },
                // {
                //     label: this.$t('FLOWPATHS'),
                //     icon: 'minus-thick',
                //     color: scssVars.hydroFlowpath,
                // },
                // {
                //     label: this.$t('CATCHMENTS'),
                //     icon: 'minus-thick',
                //     color: scssVars.hydroWaterbody,
                // },
            ],

            passabilityStatuses: [
                {
                    label: this.$t('PASSABLE'),
                    color: scssVars.statusPassable,
                    symbolText: '\u{F012C}',
                },
                {
                    label: this.$t('BARRIER'),
                    color: scssVars.statusBarrier,
                    symbolText: '!',
                },
                {
                    label: this.$t('PARTIAL_BARRIER'),
                    color: scssVars.statusPartial,
                    symbolText: '\u{F01D9}',
                },
                {
                    label: this.$t('UNKNOWN'),
                    color: scssVars.statusUnknown,
                    symbolText: '?',
                },
                {
                    label: this.$t('NA_NO_STRUCTURE'),
                    color: scssVars.statusNone,
                    symbolText: '\u{F0156}',
                },
                {
                    label: this.$t('NA_REMOVED'),
                    color: scssVars.statusRemoved,
                    symbolText: '/',
                },
            ],
        };
    },
};
</script>

<style lang="scss">
.map-legend-control {
    position: absolute;
    top: 112px; // below watershed and locator control
    right: 12px + 10px; // same as locator control (margin + container margin)

    .map-legend {
        padding: 15px;

        @media screen and (max-width: 1350px) {
            padding: 10px;
        }

        @media screen and (max-width: 1300px) {
            padding: 5px;
        }
    }

    .legend-group:not(:last-child) {
        margin-bottom: 1em;
    }

    .legend-list {
        display: grid;
        grid-template-columns: 1fr 1fr;

        li {
            margin: 0.25em;
            margin-right: 0.75em;
            display: flex;
            align-items: center;

            .status-icon,
            .type-icon {
                color: $black;
                font-size: 0.75em;
                margin-right: 0.5em;
                height: 1.5em;
            }

            .status-icon {
                text-align: center;
                font-size: 1em;
                border: 2px solid transparent;
                font-family: 'Material Design Icons', sans-serif;
            }
        }
    }

    .close-button {
        position: absolute;
        top: 0;
        right: 0;
        color: $cabd-red;
        cursor: pointer;
        box-shadow: none;
        border: none;
        border-radius: 0;
        outline: none;
    }
}
</style>
