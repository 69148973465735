<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
    name: 'App',
    created() {
        this.$i18n.locale = this.$route.params.locale;
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/appStyles.scss";

html {
    height: 100%;
    width: 100%;
    font-family: Lato, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
}

#app {
    width: 100%;
    height: 100%;
}
</style>
