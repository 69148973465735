/* eslint-disable max-len */
// eslint-disable-next-line import/prefer-default-export
export const messages = {
    en: {
        ABOUT: 'The CABD is a multi-year project that is supported partially by financial contributions from Fisheries and Oceans Canada. The database is also funded in part by the RBC Foundation in support of RBC Tech for Nature.',
        ABANDONED__ORPHANED: 'Abandoned/Orphaned',
        ACCESSIBILITY_MODE: 'Accessibility Mode',
        ACTIVE: 'Active',
        ADD_PROVINCE: 'Add a province or territory',
        ADD_WATERSHED: 'Add a watershed',
        ADDING_MODELLED_CROSSING: 'Adding modelled crossings layers',
        ADDING_NHN: 'Adding NHN watershed layers',
        ADDING_WATERBODY: 'Adding waterbody layers',
        ALL: 'All',
        API_CRITICAL_ERROR: 'There was a problem loading this page.<br> Please try again in a moment.',
        APPLY_FILTERS: 'Apply Filters',
        APPLYING_FILTERS: 'Applying Filters',
        ARCHITECT: 'Architect',
        ASSESSMENT_SCHEDULE: 'Assessment Schedule',
        ATTRACTION_ESTIMATE_: 'Attraction Estimate (%)',
        AVERAGE_RATE_OF_DISCHARGE_L_S: 'Average Rate of Discharge (L/S)',
        AVERAGE_VELOCITY_OF_WATER_FLOW_M_S: 'Averate Velocity of Water Flow (M/S)',
        BARRIER: 'Barrier',
        BARRIER_IDENTIFIER: 'Barrier Identifier',
        CABD_ID: 'CABD ID',
        CANADIAN_AQUATIC_BARRIERS_DATABASE: 'Canadian Aquatic Barriers Database',
        CATCHMENTS: 'Catchments',
        CHARITY__NON_PROFIT: 'Charity/Non-profit',
        CLEAR_FILTERS: 'Clear Filters',
        CLICK_DOWNLOAD: 'Click to Download',
        CLICK_JOIN_MAIL_LIST: 'Add me to the CABD mailing list',
        CLICK_LEAVE_MAIL_LIST: 'Click here to unsubscribe to the mailing list',
        CLICK_TO_UNSUB: 'Click here to unsubscribe from the mailing list',
        COMMENTS: 'Comments',
        COMPLETE: 'Complete',
        COMPLETENESS_LEVEL: 'Completeness Level',
        CONSENT_MESSAGE: 'I consent to the collection and processing of my personal information in accordance with the CWF ',
        CONSTRUCTED_BY: 'Constructed By',
        CONSTRUCTION_MATERIAL: 'Construction Material',
        CONSTRUCTION_TYPE: 'Construction Type',
        CONSTRUCTION_YEAR: 'Construction Year',
        CONTACT_US: 'Contact Us',
        CONTRACTED_BY: 'Contracted By',
        CSV: 'CSV',
        CSV_DATA_EXPORT: 'Starting data export.',
        DAM: 'Dam',
        DAM___ARCH: 'Arch',
        DAM___BUTTRESS: 'Buttress',
        DAM___EMBANKMENT: 'Embankment',
        DAM___GRAVITY: 'Gravity',
        DAM___MULTIPLE_ARCH: 'Multiple Arch',
        DAM___OTHER: 'Other Dam',
        WEIR: 'Weir',
        SPILLWAY: 'Spillway',
        POWERHOUSE: 'Powerhouse',
        LATERAL_BARRIER: 'Lateral Barrier',
        LOCK: 'Lock',
        ABOITEAU__TIDE_GATE: 'Tide Gate',
        DAM_CONDITION: 'Dam Condition',
        DAM_FUNCTION: 'Dam Function',
        DAM_IDENTIFIER: 'Dam Identifier',
        DAM_NAME_ENGLISH: 'Dam name (English)',
        DAM_NAME_FRENCH: 'Dam name (French)',
        DAM_PRESENT: 'Dam present',
        DAM_SIZE: 'Dam Size',
        DAM_USE: 'Dam Use',
        DAMS: 'Dams',
        DATA_SET: 'Data Set:',
        DATA_SOURCES: 'Data Sources',
        DECOMMISSIONED__REMOVED: 'Decommissioned/Removed',
        DEGREE_OF_REGULATION: 'Degree of Regulation',
        DENIL: 'Denil',
        DESIGNED_BASED_ON_BIOLOGY: 'Designed based on biology',
        DETAILED_VIEW: 'Detailed View',
        DOCUMENTATION_LINK: 'https://cabd-docs.netlify.app/',
        DOCUMENTATION_SITE: 'Documentation Site',
        DOWNLOAD: 'Download',
        DOWNLOAD_DATA: 'Download Data',
        DOWNLOAD_FEATURE_TYPE: 'Feature type to Download:',
        DOWNLOAD_FILE_TYPE: 'Download File Type:',
        DOWNLOAD_NOTE: 'Note: Only one feature type layer can be downloaded at a time. If you wish to download multiple feature types, you must select and download each type separately.',
        DOWNSTREAM_PASSAGE_ROUTE: 'Downstream Passage Route',
        EDIT_FILTERS: 'Edit Filters',
        EEL_LADDER: 'Eel Ladder',
        ELEVATION_M: 'ELEVATION (M)',
        ENGINEERING_NOTES: 'Engineering Notes',
        ENTRANCE_LOCATION: 'Entrance Location',
        ENTRANCE_POSITION: 'Entrance Position',
        EPSG: 'EPSG',
        EPSG_CODE: 'EPSG code',
        ERROR_LOCATION: 'Error getting searched location',
        EVALUATING_STUDY: 'Evaluating Study',
        EXPLORE_DATA: 'Explore Data',
        EXPECTED_END_OF_LIFE: 'Expected End of Life',
        EXPECTED_LIFE_YEARS: 'Expected Life (Years)',
        FACILITY: 'Facility',
        FACILITY_NAME: 'Facility Name',
        FACILITY_NAME_ENGLISH: 'Facility name (English)',
        FACILITY_NAME_FRENCH: 'Facility name (French)',
        FALL_HEIGHT_M: 'Fall Height (M)',
        FALL_NAME_ENGLISH: 'Fall Name (English)',
        FALL_NAME_FRENCH: 'Fall Name (French)',
        FEATURE_DATA_SOURCE_DETAILS: 'Feature Data Source Details',
        FEATURE_TYPE: 'Structure Type',
        FEATURE_TYPE_TO_DOWNLOAD: 'Feature type to Download',
        FEDERAL: 'Federal',
        FEDERAL_COMPLIANCE_STATUS: 'Federal Compliance Status',
        FEDERAL_FLOW_REQUIREMENTS_M3_S: 'Federal Flow Requirements (m3/s)',
        FIELD_REQUIRED: 'This field is required',
        FIELD_REQUIRED_BETWEEN: 'This value must be between {min} and {max}',
        FIELD_REQUIRED_LAT_LON: 'Use format: "latitude,longitude". Latitude must be between -90 & 90. Longitude must be between -180 & 180',
        FIELD_REQUIRED_NUMBER: 'This field must be a number',
        FILTER_BY: 'Filter By:',
        FILTER_DATA: 'Filter Data',
        FILTER_DEFINITIONS: [
            {
                type: 'dams',
                label: 'Dams',
                icon: 'square',
                layerListExpanded: false,
                layers: ['cabd-dams', 'cabd-dams-outline'],
                passabilityLayers: {
                    1: 'cabd-dams-barrier-symbology',
                    2: 'cabd-dams-partial-barrier-symbology',
                    4: 'cabd-dams-unknown-symbology',
                },
                active: true,
                subfilters: [
                    {
                        header: 'Passability',
                        attributeName: 'passability_status_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Operating Status',
                        attributeName: 'operating_status_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Ownership Type',
                        attributeName: 'ownership_type_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Dam Use',
                        attributeName: 'use_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Dam Size',
                        attributeName: 'size_class_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Used for Network Analysis',
                        attributeName: 'use_analysis',
                        type: 'boolean',
                    },
                    {
                        header: 'Dam Height (m)',
                        attributeName: 'height_m',
                        type: 'range',
                    },
                    {
                        header: 'Fishway Present',
                        attributeName: 'up_passage_type',
                        type: 'boolean',
                    },
                    {
                        header: 'Structure Type',
                        attributeName: 'structure_type_code',
                        type: 'checkbox',
                    },
                    // {
                    //     header: 'Construction year',
                    //     attributeName: 'construction_year',
                    //     type: 'range',
                    // },
                ],
            },
            {
                type: 'waterfalls',
                label: 'Waterfalls',
                icon: 'triangle',
                layerListExpanded: false,
                layers: ['cabd-waterfalls', 'cabd-waterfalls-outline'],
                passabilityLayers: {
                    1: 'cabd-waterfalls-barrier-symbology',
                    2: 'cabd-waterfalls-partial-barrier-symbology',
                    4: 'cabd-waterfalls-unknown-symbology',
                },
                active: true,
                subfilters: [
                    {
                        header: 'Passability',
                        attributeName: 'passability_status_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Used for Network Analysis',
                        attributeName: 'use_analysis',
                        type: 'boolean',
                    },
                    // {
                    //     header: 'Waterfall Height (m)',
                    //     attributeName: 'fall_height_m',
                    //     type: 'range',
                    // },
                ],
            },
            {
                type: 'fishways',
                label: 'Fishways',
                layers: ['cabd-fishways', 'cabd-fishways-outline'],
                active: true,
                icon: 'fish',
                layerListExpanded: false,
                subfilters: [
                    {
                        header: 'Fishway Type',
                        attributeName: 'fishpass_type_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Dam Present',
                        attributeName: 'dam_id',
                        type: 'boolean',
                    },
                    // {
                    //     header: 'Construction year',
                    //     attributeName: 'year_constructed',
                    //     type: 'range',
                    // },
                ],
            },
            {
                type: 'modelled_crossings',
                label: 'Modelled Crossings',
                layers: ['cabd-modelled_crossings'],
                active: true,
                icon: 'circle',
                subfilters: [
                    {
                        header: 'Passability',
                        attributeName: 'passability_status_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Crossing Type',
                        attributeName: 'crossing_type_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Crossing Subtype',
                        attributeName: 'crossing_subtype_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Use Analysis',
                        attributeName: 'use_analysis',
                        type: 'boolean',
                    },
                    {
                        header: 'Addressed Status',
                        attributeName: 'addressed_status',
                        type: 'checkbox',
                    },
                    {
                        header: 'Assessment Type',
                        attributeName: 'assessment_type',
                        type: 'checkbox',
                    },
                ],
            },
            // {
            //     type: 'flowpaths',
            //     label: 'Flowpaths',
            //     layers: ['cabd-flowpath'],
            //     active: false,
            //     subfilters: [],
            // },
            // {
            //     type: 'catchments',
            //     label: 'Catchments',
            //     layers: ['cabd-waterbody'],
            //     active: false,
            //     subfilters: [],
            // },
            {
                type: 'nhn-work-units',
                label: 'NHN Watershed Boundaries',
                layers: ['cabd-nhn-work-units-outline'],
                active: false,
                subfilters: [],
                opacity: 0.5,
            },
        ],
        FILTERED: 'Filtered',
        FILTERS: 'Filters',
        FISHERIES: 'Fisheries',
        FISHWAY: 'Fishway',
        FISHWAY_PRESENT: 'Fishway Present',
        FISHWAY_TYPE: 'Fishway Type',
        FISHWAYS: 'Fishways',
        FLOOD_CONTROL: 'Flood Control',
        FLOWPATHS: 'Flowpaths',
        GENERATING_CAPACITY_MWH: 'Generating Capacity (MWH)',
        GEOPACKAGE: 'Geopackage',
        GO: 'GO',
        GRADIENT: 'Gradient',
        GRAVITY: 'Gravity',
        HAS_EVALUATING_STUDIES: 'Has Evaluating Studies',
        HAS_HYDRO_PEAKING_SYSTEM: 'Has Hydro Peaking System',
        HEIGHT_M: 'Height (M)',
        HOVER_TEXT: 'Hover over a watershed to get its name & ID',
        HYDRO_NETWORK: 'Hydro Network',
        HYDRO_NETWORKS: 'Hydro Networks',
        HYDROELECTRICITY: 'Hydroelectricity',
        INDIGENOUS: 'Indigenous',
        INVASIVE_SPECIES_CONTROL: 'Invasive Species Control',
        IRRIGATION: 'Irrigation',
        IS_MODIFIED: 'Is modified',
        KML: 'KML',
        LAKE_CONTROL: 'Lake Control',
        LARGE: 'Large',
        LAST_MAINTENANCE_DATE: 'Last Maintenance Date',
        LAST_MODIFIED: 'Last Modified',
        LAT: 'Lat.',
        LAT_LNG: 'Lat / Lng',
        LATITUDE: 'Latitude',
        LAYERS: 'Layers',
        LEGEND: 'Legend',
        LENGTH_M: 'Length (M)',
        LNG: 'Long.',
        LOAD_FEATURE_INFO: 'Getting detailed feature information',
        LOADING_POINTS: 'Loading feature points',
        LOADING_TYPE: 'Loading feature types',
        LOCATION: 'Location',
        LOGO: '@/assets/cwf_logo_english.svg',
        LONGITUDE: 'Longitude',
        MAIL_DISCLAIMER: 'By joining the mailing list, you will receive the latest news on CABD updates and data releases directly to your inbox.',
        MAP_VIEW: 'Map View',
        MAXIMUM_VELOCITY_OF_WATER_FLOW_M_S: 'Maximum Velocity of Water Flow (M/S)',
        MEAN_CHANNEL_DEPTH_M: 'Mean Channel Depth (M)',
        MEDIUM: 'Medium',
        MINIMAL: 'Minimal',
        MODELLED_CROSSINGS: 'Modelled Crossings',
        MODELLED_CROSSINGS_TOOLTIP: 'Modelled Crossings only appear at lower zoom levels',
        MODERATE: 'Moderate',
        MODIFICATION_PURPOSE: 'Modification Purpose',
        MODIFICATION_YEAR: 'Modification Year',
        MONITORING_EQUIPMENT: 'Monitoring Equipment',
        MUNICIPAL: 'Municipal',
        MUNICIPALITY: 'Municipality',
        NA: 'N/A',
        NA_NO_STRUCTURE: 'NA - No Structure',
        NA_REMOVED: 'NA - Removed/Decommissioned',
        NATURE_LIKE_FISHWAY: 'Nature-like fishway',
        NATURE_OF_EVALUATION_STUDIES: 'Nature of Evaluation Studies',
        NAVIGATION: 'Navigation',
        NEXT_MAINTENANCE_DATE: 'Next Maintenance Date',
        NHN_WATERSHED_BOUNDARIES: 'NHN Watershed Boundaries',
        NHN_WATERSHED_ID: 'NHN Watershed ID',
        NHN_WATERSHED_NAME: 'NHN Watershed Name',
        NO: 'No',
        NO_STRUCTURE: 'No structure',
        NUMBER_OF_TURBINES: 'Number of Turbines',
        OPERATION_NOTES: 'Operation Notes',
        OPERATION_PERIOD: 'Operation Period',
        OPERATED_BY: 'Operated By',
        OPERATING_NOTE: 'Operating Note',
        OPERATING_NOTES: 'Operating Notes',
        OPERATING_STATUS: 'Operating Status',
        OTHER: 'Other',
        OWNER: 'Owner',
        OWNERSHIP_TYPE: 'Ownership Type',
        PARTIAL_BARRIER: 'Partial Barrier',
        PASSABLE: 'Passable',
        PASSABILITY: 'Passability',
        PASSABILITY_STATUS: 'Passability Status',
        PASSABILITY_STATUS_NOTE: 'Passability Status Note',
        PLACEHOLDER_DATA: 'Data Source',
        PLACEHOLDER_DESCRIPTION: 'Enter updated information about the structure here (Required)',
        PLACEHOLDER_EMAIL: 'Email (Required)',
        PLACEHOLDER_NAME: 'Name (Required)',
        PLACEHOLDER_ORGANIZATION: 'Organization',
        PLACE: 'Place',
        PLACES: 'Places',
        PLANS_HELD_BY: 'Plans held by',
        POLLUTION_CONTROL: 'Pollution Control',
        POOL_AND_WEIR: 'Pool and weir',
        POOL_AND_WEIR_WITH_HOLE: 'Pool and weir with hole',
        PRIVACY_DISCLAIMER: 'By providing your personal information to the Canadian Wildlife Federation through the CABD web tool, you consent to its collection, use and disclosure in accordance with the ',
        PRIVACY_LINK: 'https://cwf-fcf.org/en/about-cwf/policies/privacy-policy.html',
        PRIVACY_NOTICE: 'Privacy Notice',
        PRIVACY_NOTICE_TEXT: 'This site and the Canadian Aquatics Barriers Database is operated by the Canadian Wildlife Federation (“CWF”). CWF is responsible for all information, including personal information, collected via www.aquaticbarriers.ca. For further information concerning the CWF’s treatment of personal information, please read the CWF’s ',
        PRIVACY_POLICY: 'Privacy Policy',
        PRIVACY_POLICY_LINK: 'CABD Privacy Policy',
        PRIVATE: 'Private',
        PROVINCE_TERRITORY: 'Province/Territory',
        PROVINCE_TERRITORY_NAME: 'Province/Territory Name',
        PROVINCIAL_COMPLIANCE_STATUS: 'Provincial Compliance Status',
        PROVINCIAL_FLOW_REQUIREMENTS_M3_S: 'Provincial Flow Requirements (m3/s)',
        PROVINCIAL__TERRITORIAL: 'Provincial/Territorial',
        PURPOSE_OF_FISHWAY: 'Purpose of fishway',
        RECREATION: 'Recreation',
        REFERENCE_IDENTIFIER: 'Reference Identifier',
        REMEDIATED: 'Remediated',
        RESERVOIR_AREA_KM2: 'Reservoir Area (KM2)',
        RESERVOIR_DEPTH_M: 'Reservoir Depth (M)',
        RESERVOIR_NAME_ENGLISH: 'Reservoir Name (English)',
        RESERVOIR_NAME_FRENCH: 'Reservoir Name (French)',
        RESERVOIR_PRESENT: 'Reservoir Present',
        RETIRED__CLOSED: 'Retired/Closed',
        RIVER_STREAM_NAME_ENGLISH: 'River Stream Name (English)',
        RIVER_STREAM_NAME_FRENCH: 'River Stream Name (French)',
        SEARCH: 'Search',
        SHAPEFILE: 'Shapefile',
        SIMPLE_VIEW: 'Simple View',
        SMALL: 'Small',
        SPECIES_KNOWN_TO_NOT_USE: 'Species known to not use',
        SPECIES_KNOWN_TO_USE: 'Species known to use',
        SPILLWAY_CAPACITY: 'Spillway Capacity',
        SPILLWAY_TYPE: 'Spillway Type',
        STORAGE_CAPACITY_MCM: 'Storage Capacity (MCM)',
        STRUCTURE: 'Structure',
        STRUCTURE_NAME: 'Structure Name',
        STRUCTURE_NAME_ENGLISH: 'Structure Name (English)',
        STRUCTURE_NAME_FRENCH: 'Structure Name (French)',
        STRUCTURE_TYPE: 'Structure Type',
        SUBMIT: 'Submit',
        SYSTEM_IDENTIFIER: 'System Identifier',
        SWITCH_LANGUAGE: 'En Francais',
        OTHER_LANGUAGE: 'Francais',
        TERMS_OF_USE: 'Terms of Use',
        TRAP_AND_TRUCK: 'Trap and truck',
        TRANSIT_SUCCESS_ESTIMATE_: 'Transit success estimate (%)',
        TURBINE_TYPE: 'Turbine Type',
        UNKNOWN: 'Unknown',
        UNNAMED: 'Unnamed',
        UNVERIFIED: 'Unverified',
        UPDATE_INFORMATION: 'Update This Information',
        UPDATES_PENDING: 'Updates Pending',
        UPLOAD_SUCCESS: 'Successfully Uploaded Data',
        UPLOAD_FAILURE: 'Failed to Upload Data',
        UPLOAD_MISSING: 'Missing Required Fields',
        UPSTREAM_CATCHMENT_AREA_KM2: 'Upstream Catchment Area (KM2)',
        UPSTREAM_LINEAR_LENGTH_KM: 'Upstream Linear Length (KM)',
        UPSTREAM_PASSAGE_TYPE: 'Upstream Passage Type',
        USE_FISHERIES: 'Use Fisheries',
        USE_FLOOD_CONTROL: 'Use Flood Control',
        USE_FOR_NETWORK_ANALYSIS: 'Use for Network Analysis',
        USE_HYDROELECTRICITY: 'Use Hydroelectricity',
        USE_INVASIVE_SPECIES_CONTROL: 'Use Invasive Species Control',
        USE_IRRIGATION: 'Use Irrigation',
        USE_NAVIGATION: 'Usees Navivgation',
        USE_OTHER: 'Use Other',
        USE_POLLUTION_CONTROL: 'Use Pollution Control',
        USE_RECREATION: 'Use Recreatiion',
        USE_WATER_SUPPLY: 'Use Water Supply',
        USE_WILDLIFE_CONSERVATION: 'Use Wildlife Conservation',
        VERTICAL_SLOT: 'Vertical slot',
        WATER_SUPPLY: 'Water Supply',
        WATERBODY: 'Waterbody',
        WATERBODY_NAME_ENGLISH: 'Waterbody Name (English)',
        WATERBODY_NAME_FRENCH: 'Waterbody Name (French)',
        WATERFALL: 'Waterfall',
        WATERFALLS: 'Waterfalls',
        WATERSHED: 'Watershed',
        WILDLIFE_CONSERVATION: 'Wildlife Conservation',
        X_COORD: 'X Coord',
        Y_COORD: 'Y coord',
        YEAR_CONSTRUCTED: 'Year Constructed',
        YEAR_REMOVED: 'Year Removed',
        YES: 'Yes',
    },
    fr: {
        ABOUT: 'La BDOAC est un projet pluriannuel en partie financé par Pêches et Océans Canada. Il est également financé en partie par la Fondation RBC dans le cadre de Techno nature RBC.',
        ABANDONED__ORPHANED: 'Abandonné/Orphelin',
        ACCESSIBILITY_MODE: 'Mode accessible',
        ACTIVE: 'En usage',
        ADD_PROVINCE: 'Ajouter province ou territoire',
        ADD_WATERSHED: 'Ajouter bassin versant',
        ADDING_MODELLED_CROSSING: 'Ajout des couches des traversées repérées',
        ADDING_NHN: 'Ajout des couches des bassins versants RHN',
        ADDING_WATERBODY: 'Ajout des couches des plans d’eau',
        ALL: 'Tout',
        API_CRITICAL_ERROR: 'Un problème est survenu lors du chargement de cette page.<br> Veuillez réessayer dans un instant.',
        APPLY_FILTERS: 'Appliquez les filtres',
        APPLYING_FILTERS: 'Application des filtres',
        ARCHITECT: 'Architecte',
        ASSESSMENT_SCHEDULE: 'Horaire d’évaluation',
        ATTRACTION_ESTIMATE_: 'Estimation de l’attraction (%)',
        AVERAGE_RATE_OF_DISCHARGE_L_S: 'Débit moyen (l/s)',
        AVERAGE_VELOCITY_OF_WATER_FLOW_M_S: 'Vitesse moyenne du débit (m/s)',
        BARRIER: 'Obstacle',
        BARRIER_IDENTIFIER: 'Identifiant de l’obstacle',
        CABD_ID: 'ID BDOAC',
        CANADIAN_AQUATIC_BARRIERS_DATABASE: 'Base de données sur les obstacles aquatiques du Canada',
        CATCHMENTS: 'Bassins récepteurs',
        CHARITY__NON_PROFIT: 'Org. de bienfaisance/sans but lucratif',
        CLEAR_FILTERS: 'Réinitialiser les filtres',
        CLICK_DOWNLOAD: 'Cliquez pour télécharger',
        CLICK_JOIN_MAIL_LIST: 'Add me to the CABD mailing list',
        CLICK_LEAVE_MAIL_LIST: 'Click here to unsubscribe to the mailing list',
        CLICK_TO_UNSUB: 'Click here to unsubscribe to the mailing list',
        COMMENTS: 'Commentaires',
        COMPLETE: 'Complet',
        COMPLETENESS_LEVEL: 'Quantité d’info',
        CONSENT_MESSAGE: 'Je consens à la cueillette et au traitement de mes données personnelles conformément à la politique de confidentialité de la FCF.',
        CONSTRUCTED_BY: 'Construit par',
        CONSTRUCTION_MATERIAL: 'Materiel de Construction',
        CONSTRUCTION_TYPE: 'Type de matériaux',
        CONSTRUCTION_YEAR: 'Année de construction',
        CONTACT_US: 'Écrivez-nous',
        CONTRACTED_BY: 'Contrat émis par',
        CSV: 'CSV',
        CSV_DATA_EXPORT: 'Démarrage de l\'exportation des données.',
        DAM: 'Barrage',
        DAM_CONDITION: 'État du barrage',
        DAM_FUNCTION: 'Fonction du barrage',
        DAM_IDENTIFIER: 'Identifiant du barrage',
        DAM_NAME_ENGLISH: 'Nom du barrage (anglais)',
        DAM_NAME_FRENCH: 'Nom du barrage (français)',
        DAM_PRESENT: 'Présence d’un barrage',
        DAM_SIZE: 'Dimensions du barrage',
        DAM_USE: 'Usage du barrage',
        DAMS: 'Barrages',
        DATA_SET: 'Jeu de données:',
        DATA_SOURCES: 'Sources des données',
        DECOMMISSIONED__REMOVED: 'Démantelé/Retiré',
        DEGREE_OF_REGULATION: 'Niveau de réglementation',
        DENIL: 'Passe à déflecteurs',
        DESIGNED_BASED_ON_BIOLOGY: 'Conçue selon la biologie',
        DETAILED_VIEW: 'Vue détaillée',
        DOCUMENTATION_LINK: 'https://cabd-docs-fr.netlify.app/',
        DOCUMENTATION_SITE: 'Site de documentation',
        DOWNLOAD: 'Télécharger',
        DOWNLOAD_DATA: 'Télécharger les données',
        DOWNLOAD_FEATURE_TYPE: 'Type d’élément à télécharger:',
        DOWNLOAD_FILE_TYPE: 'Type de fichier à télécharger:',
        DOWNLOAD_NOTE: 'Note : Vous ne pouvez télécharger qu’une seule couche des types d’éléments à la fois. Si vous souhaitez télécharger plus d’un type d’élément, vous devez sélectionner et télécharger chaque type séparément.',
        DOWNSTREAM_PASSAGE_ROUTE: 'Parcours pour traverser en aval',
        EDIT_FILTERS: 'Modifier les filtres',
        EEL_LADDER: "Échelle à anguilles",
        ELEVATION_M: 'Élévation (m)',
        ENGINEERING_NOTES: 'Notes sur l’ingénierie',
        ENTRANCE_LOCATION: 'Emplacement de l’entrée',
        ENTRANCE_POSITION: 'Position de l’entrée',
        EPSG: 'EPSG',
        EPSG_CODE: 'Code EPSG',
        ERROR_LOCATION: 'Erreur: emplacement introuvable',
        EVALUATING_STUDY: 'Étude pour évaluation',
        EXPLORE_DATA: 'Explorer les données',
        EXPECTED_LIFE_YEARS: 'Durée utile probable (années)',
        EXPECTED_END_OF_LIFE: 'fin de vie prévue',
        FACILITY: 'L’installation',
        FACILITY_NAME: 'Nom de l’installation',
        FACILITY_NAME_ENGLISH: 'Nom de l’installation (anglais)',
        FACILITY_NAME_FRENCH: 'Nom de l’installation (français)',
        FALL_HEIGHT_M: 'Hauteur de la chute (m)',
        FALL_NAME_ENGLISH: 'Nom de l’installation (anglais)',
        FALL_NAME_FRENCH: 'Nom de l’installation (français)',
        FEATURE_DATA_SOURCE_DETAILS: 'Détails sur la source des données sur l’élément',
        FEATURE_TYPE: 'Type d’élément',
        FEATURE_TYPE_TO_DOWNLOAD: 'Type d’élément à télécharger',
        FEDERAL: 'Fédéral',
        FEDERAL_COMPLIANCE_STATUS: 'Conformité aux lois fédérales',
        FEDERAL_FLOW_REQUIREMENTS_M3_S: 'Débit, exigences fédérales (m3/s)',
        FIELD_REQUIRED: 'Ce champ est obligatoire',
        FIELD_REQUIRED_BETWEEN: 'Cette valeur doit être entre {min} et {max}',
        FIELD_REQUIRED_LAT_LON: 'Utilisez le format ‘latitude, longitude’. La latitude doit être entre -90 et 90. La longitude doit être entre -180 et 180.',
        FIELD_REQUIRED_NUMBER: 'Ce champ doit être un numéro',
        FILTER_BY: 'Filtrer selon:',
        FILTER_DATA: 'Filtrer les données',
        FILTER_DEFINITIONS: [
            {
                type: 'dams',
                label: 'dams',
                icon: 'square',
                layerListExpanded: false,
                layers: ['cabd-dams', 'cabd-dams-outline'],
                passabilityLayers: {
                    1: 'cabd-dams-barrier-symbology',
                    2: 'cabd-dams-partial-barrier-symbology',
                    4: 'cabd-dams-unknown-symbology',
                },
                active: true,
                subfilters: [
                    {
                        header: 'Passage',
                        attributeName: 'passability_status_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'État de fonctionnement',
                        attributeName: 'operating_status_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Type de propriété',
                        attributeName: 'ownership_type_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Usage du barrage',
                        attributeName: 'use_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Dimensions du barrage',
                        attributeName: 'size_class_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Utilisé pour analyse du réseau',
                        attributeName: 'use_analysis',
                        type: 'boolean',
                    },
                    // {
                    //     header: 'Dam Height (m)',
                    //     attributeName: 'height_m',
                    //     type: 'range',
                    // },
                    {
                        header: 'Présence de passe à poissons',
                        attributeName: 'up_passage_type',
                        type: 'boolean',
                    },
                    // {
                    //     header: 'Construction year',
                    //     attributeName: 'construction_year',
                    //     type: 'range',
                    // },
                ],
            },
            {
                type: 'waterfalls',
                label: 'waterfalls',
                icon: 'triangle',
                layerListExpanded: false,
                layers: ['cabd-waterfalls', 'cabd-waterfalls-outline'],
                passabilityLayers: {
                    1: 'cabd-waterfalls-barrier-symbology',
                    2: 'cabd-waterfalls-partial-barrier-symbology',
                    4: 'cabd-waterfalls-unknown-symbology',
                },
                active: true,
                subfilters: [
                    {
                        header: 'Passage',
                        attributeName: 'passability_status_code',
                        type: 'checkbox',
                    },
                    // {
                    //     header: 'Waterfall Height (m)',
                    //     attributeName: 'fall_height_m',
                    //     type: 'range',
                    // },
                ],
            },
            {
                type: 'fishways',
                label: 'fishways',
                layers: ['cabd-fishways', 'cabd-fishways-outline'],
                active: true,
                icon: 'fish',
                layerListExpanded: false,
                subfilters: [
                    {
                        header: 'Type de passe à poissons',
                        attributeName: 'fishpass_type_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Présence d’un barrage',
                        attributeName: 'dam_id',
                        type: 'boolean',
                    },
                    // {
                    //     header: 'Construction year',
                    //     attributeName: 'year_constructed',
                    //     type: 'range',
                    // },
                ],
            },
            {
                type: 'modelled_crossings',
                label: 'Modelled Crossings',
                layers: ['cabd-modelled_crossings'],
                active: true,
                icon: 'circle',
                subfilters: [
                    {
                        header: 'Passage',
                        attributeName: 'passability_status_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Type de traverse',
                        attributeName: 'crossing_type_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Sous-type de traversées',
                        attributeName: 'crossing_subtype_code',
                        type: 'checkbox',
                    },
                    {
                        header: 'Utilisé pour analyse du réseau',
                        attributeName: 'use_analysis',
                        type: 'boolean',
                    },
                    {
                        header: 'Statut Adressé',
                        attributeName: 'addressed_status',
                        type: 'checkbox',
                    },
                    {
                        header: 'Type d’évaluation',
                        attributeName: 'assessment_type',
                        type: 'checkbox',
                    },
                ],
            },
            // {
            //     type: 'flowpaths',
            //     label: 'Trajectoires de l’eau',
            //     layers: ['cabd-flowpath'],
            //     active: false,
            //     subfilters: [],
            // },
            // {
            //     type: 'catchments',
            //     label: 'TRANSLATE ME - catchments',
            //     layers: ['cabd-waterbody'],
            //     active: false,
            //     subfilters: [],
            // },
            {
                type: 'nhn-work-units',
                label: 'ID Bassin Versant RHN',
                layers: ['cabd-nhn-work-units-outline'],
                active: false,
                subfilters: [],
                opacity: 0.5,
            },
        ],
        FILTERED: 'Avec filtres',
        FILTERS: 'Filtres',
        FISHERIES: 'Pêches',
        FISHWAY: 'Passe à poissons',
        FISHWAY_PRESENT: 'Présence de passe à poissons',
        FISHWAY_TYPE: 'Type de passe à poissons',
        FISHWAYS: 'Passes à poissons',
        FLOOD_CONTROL: 'Contrôle des inondations',
        FLOWPATHS: 'Trajectoires de l’eau',
        GENERATING_CAPACITY_MWH: 'Capacité de Production (MWH)',
        GEOPACKAGE: 'GeoPackage',
        GO: 'Lancer',
        GRADIENT: 'Pente',
        GRAVITY: 'Gravité',
        HAS_EVALUATING_STUDIES: 'Études effectuées pour évaluation',
        HAS_HYDRO_PEAKING_SYSTEM: 'Système pour la demande de pointe',
        HEIGHT_M: 'Hauteur (m)',
        HOVER_TEXT: 'Passez le curseur sur un bassin versant pour voir son nom et ID',
        HYDRO_NETWORK: 'Réseau hydro',
        HYDRO_NETWORKS: 'Réseaux hydro',
        HYDROELECTRICITY: 'Hydroélectricité',
        ID_BASSIN_VERSANT_RHN: 'ID Bassin Versant RHN',
        INDIGENOUS: 'Indigène',
        INVASIVE_SPECIES_CONTROL: 'Contrôle des espèces envahissantes',
        IRRIGATION: 'Irrigation',
        IS_MODIFIED: 'Est modifiée',
        KML: 'KML',
        LAKE_CONTROL: 'Régulateur du niveau du lac',
        LARGE: 'Grand',
        LAST_MAINTENANCE_DATE: 'Date de la dernière maintenance',
        LAST_MODIFIED: 'Dernières modifications',
        LAT: 'Lat.',
        LAT_LNG: 'Lat / Long',
        LATITUDE: 'Latitude',
        LAYERS: 'Couches',
        LEGEND: 'Légende',
        LENGTH_M: 'Longueur (m)',
        LNG: 'Long.',
        LOAD_FEATURE_INFO: 'Obtention de l’info détaillée',
        LOADING_POINTS: 'Chargement des données',
        LOADING_TYPE: 'Chargement des types d’éléments',
        LOCATION: 'Emplacement',
        LOGO: '@/assets/cwf_logo_french.svg',
        LONGITUDE: 'Longitude',
        MAIL_DISCLAIMER: 'En vous inscrivant à l’infolettre, vous allez recevoir les plus récentes nouvelles sur les mises à jour et la publication de données dans la BDOAC directement dans votre boîte de réception.',
        MAP_VIEW: 'Carte',
        MAXIMUM_VELOCITY_OF_WATER_FLOW_M_S: 'Vitesse maximale du débit (m/s)',
        MEAN_CHANNEL_DEPTH_M: 'Profondeur moyenne du chenal (m)',
        MEDIUM: 'Moyen',
        MINIMAL: 'Minimal',
        MODELLED_CROSSINGS: 'Traversée de ruisseau',
        MODELLED_CROSSINGS_TOOLTIP: 'Traversées repérées',
        MODERATE: 'Moyen',
        MODIFICATION_PURPOSE: 'But des modifications',
        MODIFICATION_YEAR: 'Année des modifications',
        MONITORING_EQUIPMENT: 'Équipement de surveillance',
        MUNICIPAL: 'Municipal',
        MUNICIPALITY: 'Municipalité',
        NA: 's. o.',
        NA_NO_STRUCTURE: 'NA - Pas de structure',
        NA_REMOVED: 'NA - Démantelé/ Retiré',
        NATURE_LIKE_FISHWAY: 'Passe naturelle',
        NATURE_OF_EVALUATION_STUDIES: 'Type d’études effectuées pour évaluation',
        NAVIGATION: 'Navigation',
        NEXT_MAINTENANCE_DATE: 'Date de la prochaine maintenance',
        NHN_WATERSHED_BOUNDARIES: 'Limites du bassin versant RHN',
        NHN_WATERSHED_ID: 'ID Bassin Versant RHN',
        NHN_WATERSHED_NAME: 'Nom du bassin versant RHN',
        NO: 'Non',
        NO_STRUCTURE: 'Pas de structure',
        NUMBER_OF_TURBINES: 'Nombre de turbines',
        OPERATED_BY: 'Opérée par',
        OPERATING_NOTE: 'Note sur l’usage',
        OPERATING_NOTES: 'Notes sur l’usage',
        OPERATING_STATUS: 'État de fonctionnement',
        OPERATION_NOTES: 'Notes sur l’usage',
        OPERATION_PERIOD: 'Période d’usage',
        OTHER: 'Autr',
        OWNER: 'Propriétaire',
        OWNERSHIP_TYPE: 'Type de propriété',
        PARTIAL_BARRIER: 'Obstacle partiel',
        PASSABILITY: 'Passage',
        PASSABILITY_STATUS: 'État du passage',
        PASSABILITY_STATUS_NOTE: 'Note sur l’état du passage',
        PASSABLE: 'Franchissable',
        PLACEHOLDER_DATA: 'Source des données',
        PLACEHOLDER_DESCRIPTION: 'Inscrivez la nouvelle info sur l’élément ici',
        PLACEHOLDER_EMAIL: 'Courriel (Requis)',
        PLACEHOLDER_NAME: 'Nom (Requis)',
        PLACEHOLDER_ORGANIZATION: 'Organisation',
        PLACE: 'Lieu',
        PLACES: 'Lieux',
        PLANS_HELD_BY: 'Plans détenus par',
        POLLUTION_CONTROL: 'Contrôle de la pollution',
        POOL_AND_WEIR: 'En gradins',
        POOL_AND_WEIR_WITH_HOLE: 'En gradins avec trou',
        PRIVACY_DISCLAIMER: 'En fournissant vos données personnelles à la Fédération canadienne de la faune par l’entremise de l’outil Web de la BDOAC, vous consentez à leur collecte, utilisation et divulgation conformément à la politique de confidentialité de la FCF.',
        PRIVACY_LINK: 'https://cwf-fcf.org/fr/fcf/les-politiques/politique-de-confidentialit/',
        PRIVACY_NOTICE: 'Avis de confidentialité',
        PRIVACY_NOTICE_TEXT: 'Ce site et la Base de données sur les obstacles aquatiques du Canada sont gérés par la Fédération canadienne de la faune (FCF). La FCF est responsable de toute l’information, dont les données personnelles, collectée sur www.aquaticbarriers.ca. Pour de plus amples renseignements sur le traitement des données personnelles par la FCF, veuillez consulter la politique de confidentialité de la FCF.',
        PRIVACY_POLICY: 'Politique de confidentialité',
        PRIVACY_POLICY_LINK: 'Politique de confidentialité de la BDOAC',
        PRIVATE: 'Privé',
        PROVINCE_TERRITORY: 'Province/Territoire',
        PROVINCE_TERRITORY_NAME: 'Nom province/territoire',
        PROVINCIAL_COMPLIANCE_STATUS: 'Conformité aux lois provinciales',
        PROVINCIAL_FLOW_REQUIREMENTS_M3_S: 'Débit, exigences provinciales (m3/s)',
        PROVINCIAL__TERRITORIAL: 'Provincial/Territorial',
        PURPOSE_OF_FISHWAY: 'Fonction de la passe à poissons',
        RECREATION: 'Récréation',
        REFERENCE_IDENTIFIER: 'Identifiant de la référence',
        REMEDIATED: 'Modifié pour permettre le passage des poissons',
        RESERVOIR_AREA_KM2: 'Superficie du réservoir (km2)',
        RESERVOIR_DEPTH_M: 'Profondeur du réservoir (m)',
        RESERVOIR_NAME_ENGLISH: 'Nom du réservoir (anglais)',
        RESERVOIR_NAME_FRENCH: 'Nom du réservoir (français)',
        RESERVOIR_PRESENT: 'Réservoir présent',
        RETIRED__CLOSED: 'Hors d’usage/Fermé',
        RIVER_STREAM_NAME_ENGLISH: 'Nom rivière/ruisseau (anglais)',
        RIVER_STREAM_NAME_FRENCH: 'Nom rivière/ruisseau (français)',
        SEARCH: 'Recherche',
        SHAPEFILE: 'Shapefile',
        SIMPLE_VIEW: 'Vue simple',
        SMALL: 'Petit',
        SPECIES_KNOWN_TO_NOT_USE: 'Espèces qui ne l’utilisent pas',
        SPECIES_KNOWN_TO_USE: 'Espèces qui l’utilisent',
        SPILLWAY_CAPACITY: 'Capacité du déversoir',
        SPILLWAY_TYPE: 'Type de déversoir',
        STORAGE_CAPACITY_MCM: 'Capacité d’entreposage (mmc)',
        STRUCTURE: 'Structure',
        STRUCTURE_NAME: 'Nom de la structure',
        STRUCTURE_NAME_ENGLISH: 'Nom de la structure (anglais)',
        STRUCTURE_NAME_FRENCH: 'Nom de la structure (français)',
        STRUCTURE_TYPE: 'Type de structure',
        SUBMIT: 'Soumettre',
        SYSTEM_IDENTIFIER: 'Identifiant du système',
        SWITCH_LANGUAGE: 'In English',
        OTHER_LANGUAGE: 'English',
        TERMS_OF_USE: 'Conditions d’utilisation',
        TRANSIT_SUCCESS_ESTIMATE_: 'Estimation du taux de passages réussis (%)',
        TRAP_AND_TRUCK: 'Piégeage et transport',
        TRUE: 'Oui',
        TURBINE_TYPE: 'Type de turbine',
        UNKNOWN: 'Inconnu',
        UNNAMED: 'Sans nom',
        UNVERIFIED: 'Non vérifié',
        UPDATE_INFORMATION: 'Mettre cette info à jour',
        UPDATES_PENDING: 'Mises à jour prévues',
        UPLOAD_SUCCESS: 'Mises à jour acceptées',
        UPLOAD_FAILURE: 'Erreur; les mises à jour n’ont pas été acceptées',
        UPLOAD_MISSING: 'Entrez l’info dans les champs obligatoires',
        UPSTREAM_CATCHMENT_AREA_KM2: 'Superficie du bassin-versant en amont (km2)',
        UPSTREAM_LINEAR_LENGTH_KM: 'Longueur linéaire en amont (km)',
        UPSTREAM_PASSAGE_TYPE: 'Type de passage en amont',
        USE_FISHERIES: 'Usage pour pêches',
        USE_FLOOD_CONTROL: 'Usage pour contrôle des inondations',
        USE_FOR_NETWORK_ANALYSIS: 'Utilisé pour analyse du réseau',
        USE_HYDROELECTRICITY: 'Usage pour hydroélectricité',
        USE_INVASIVE_SPECIES_CONTROL: 'Usage pour contrôle d’espèces envahissantes',
        USE_IRRIGATION: 'Usage pour irrigation',
        USE_NAVIGATION: 'Usage pour navigation',
        USE_OTHER: 'Autre usage',
        USE_POLLUTION_CONTROL: 'Usage pour contrôle de la pollution',
        USE_RECREATION: 'Usage récréatif',
        USE_WATER_SUPPLY: 'Usage pour approvisionnement en eau',
        USE_WILDLIFE_CONSERVATION: 'Usage pour conservation de la faune',
        VERTICAL_SLOT: 'Fente verticale',
        WATER_SUPPLY: 'Approvisionnement en eau',
        WATERBODY: 'Plan d’eau',
        WATERBODY_NAME_ENGLISH: 'Nom du plan d’eau (anglais)',
        WATERBODY_NAME_FRENCH: 'Nom du plan d’eau (français)',
        WATERFALL: 'Chute',
        WATERFALLS: 'Chutes',
        WATERSHED: 'Bassin versant',
        WILDLIFE_CONSERVATION: 'Conservation de la faune',
        X_COORD: 'Coord. X',
        Y_COORD: 'Coord. Y',
        YEAR_CONSTRUCTED: 'Année de construction',
        YEAR_REMOVED: 'Année du retrait',
        YES: 'Oui',
    },
};
/* eslint-enable max-len */
