var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['layer-list-item', {'parent-hidden': _vm.parentHidden }],attrs:{"role":"listitem"}},[_c('div',{staticClass:"row",attrs:{"role":_vm.hasChildren(_vm.layer) ? 'none' : 'switch',"tabindex":"-1","aria-checked":_vm.layer.active,"aria-label":_vm.hasChildren(_vm.layer)
            ? ''
            : ` ${_vm.layer.label} Layer`},on:{"click":function($event){return _vm.$emit('layer-toggled', _vm.layerIndexArray, !_vm.layer.active)}}},[(_vm.layer.type==='modelled_crossings')?_c('b-tooltip',{staticClass:"icon-tooltip",attrs:{"label":_vm.$t('MODELLED_CROSSINGS_TOOLTIP'),"multilined":"","position":"is-right","size":"is-small"}},[_c('div',{staticClass:"layer-visible-icon",attrs:{"aria-checked":_vm.layer.active,"aria-label":_vm.hasChildren(_vm.layer)
                    ? `${_vm.layer.label} Layer`
                    : '',"tabindex":_vm.baseTabIndex + 1},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('layer-toggled', _vm.layerIndexArray, !_vm.layer.active)}}},[_c('b-icon',{attrs:{"pack":"mdi","size":"is-small","icon":_vm.layer.active ? 'eye-outline' : 'eye-off-outline'}})],1)]):_c('div',{staticClass:"layer-visible-icon",attrs:{"aria-checked":_vm.layer.active,"aria-label":_vm.hasChildren(_vm.layer)
                ? `${_vm.layer.label} Layer`
                : '',"tabindex":_vm.baseTabIndex + 1},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('layer-toggled', _vm.layerIndexArray, !_vm.layer.active)}}},[_c('b-icon',{attrs:{"pack":"mdi","size":"is-small","icon":_vm.layer.active ? 'eye-outline' : 'eye-off-outline'}})],1),_c('div',{staticClass:"spacer"}),(_vm.layer.icon)?_c('div',{staticClass:"layer-icon"},[_c('b-icon',{staticClass:"symbol",attrs:{"pack":"mdi","icon":_vm.layer.icon}}),_c('b-icon',{staticClass:"outline",style:(`color: ${_vm.accessibilityMode ? '#000' : '#fff'}`),attrs:{"pack":"mdi","icon":`${_vm.layer.icon}-outline`}})],1):_vm._e(),(_vm.layer.icon === null)?_c('div',{staticClass:"layer-icon"}):_vm._e(),_c('div',{staticClass:"layer-label",attrs:{"aria-checked":_vm.layer.active}},[_vm._v(" "+_vm._s(_vm.$t(_vm.layer.label.toUpperCase().replaceAll(' ', '_')))+" ")]),(_vm.layer.icon === null)?_c('div',{staticClass:"layer-icon"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }