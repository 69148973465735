import scssVars from '@/assets/scss/variables.module.scss';

export const id = 'cabd-modelled-crossings';

export const source = {
    id,
    url: 'https://cabd-web.azurewebsites.net/cabd-api/tiles/modelled_crossings/{z}/{x}/{y}.mvt',
};

const circleRadius = {
    'base': 2,
    'stops': [
        [11, 3],
        [14, 5],
        [16, 8],
    ]
};

export const mcLayer = {
    id,
    type: 'circle',
    source: source.id,
    minzoom: 9,
    paint: {
        'circle-color': [
            'match',
            ['get', 'passability_status'],
            'Passable', scssVars.statusPassable,
            'Barrier', scssVars.statusBarrier,
            'Partial Barrier', scssVars.statusPartial,
            'Unknown', scssVars.statusUnknown,
            'NA - No Structure', scssVars.statusNone,
            'NA - Decommissioned/ Removed', scssVars.statusRemoved,
            '#000',
        ],
        'circle-radius': circleRadius,
        'circle-stroke-color': '#FFF',
        'circle-stroke-width': 1,
        'circle-opacity': ['interpolate', ['exponential', 0.5], ['zoom'], 9, 0.25, 18, 1],
        'circle-stroke-opacity': ['interpolate', ['exponential', 0.5], ['zoom'], 9, 0.25, 18, 1],
    },
    'source-layer': 'default',
};

export const mcA11yLayer = {
    id: `${id}-a11y`,
    type: 'circle',
    source: source.id,
    minzoom: 9,
    paint: {
        'circle-color': '#FFF',
        'circle-radius': circleRadius,
        'circle-stroke-color': '#000',
        'circle-stroke-width': 1,
        'circle-opacity': ['interpolate', ['exponential', 0.5], ['zoom'], 9, 0.25, 18, 1],
        'circle-stroke-opacity': ['interpolate', ['exponential', 0.5], ['zoom'], 9, 0.25, 18, 1],
    },
    'source-layer': 'default',
};
export const mcTextLayer = {
    id: `${id}-a11y-text`,
    type: 'symbol',
    source: source.id,
    minzoom: 9,
    layout: {
        'text-field': [
            'match',
            ['get', 'passability_status'],
            'Passable', String.fromCharCode('0xe876'),
            'Barrier', String.fromCharCode('0xe645'),
            'Partial Barrier', String.fromCharCode('0xe5d4'),
            'Unknown', String.fromCharCode('0xeb8b'),
            'NA - No Structure', String.fromCharCode('0xe5cd'),
            'NA - Decommissioned/ Removed', '/',
            '#000',
        ],
        'text-size': {
            'base': 5,
            'stops': [
                [11, 6],
                [14, 12],
                [16, 16],
            ]
        },
        'text-font': ['Material Icons Regular'],
        "text-allow-overlap": true,
        'icon-optional': true,
        'text-offset': [0, 0.2],
    },
    'source-layer': 'default',
};
