<template>
  <b-sidebar
    v-model="showSidebar"
    type="is-light"
    fullheight
    aria-label="Downloads Sidebar"
    :can-cancel="false"
    class="download-sidebar"
    role="form"
  >
    <b-icon
      icon="chevron-double-left"
      class="close-sidebar"
      @click.native="closeSidebar"
    />
    <h2 class="modal-header subtitle is-3 has-text-centered">
      {{ $t('DOWNLOAD') }}
    </h2>
    <div class="downloads-container">
      <div class="row">
        <label class="label">{{ $t('DATA_SET') }}</label>
        <b-field>
          <b-radio
            v-model="dataSet"
            size="is-medium"
            name="name"
            native-value="filtered"
            tabindex="702"
            aria-label="Currently Filtered Radio Button"
            class="download-filtered-radio"
            @input="handleRadioButtonInput('download-filtered-radio')"
            @keydown.enter.native="handleRadioButtonInput('download-filtered-radio')"
            @keyup.enter.native="handleRadioButtonInput('download-filtered-radio')"
          >
            {{ $t('FILTERED') }}
          </b-radio>
          <b-radio
            v-model="dataSet"
            size="is-medium"
            name="name"
            native-value="all"
            tabindex="701"
            aria-label="All Data Radio Button"
            class="download-all-radio"
            @input.native="handleRadioButtonInput('download-all-radio')"
            @keydown.enter.native="handleRadioButtonInput('download-all-radio')"
            @keyup.enter.native="handleRadioButtonInput('download-all-radio')"
          >
            {{ $t('ALL') }}
          </b-radio>
          <b-radio
            v-model="dataSet"
            size="is-medium"
            name="name"
            native-value="mapview"
            tabindex="703"
            aria-label="Current Map View Radio Button"
            class="download-map-view-radio"
            @input="handleRadioButtonInput('download-map-view-radio')"
            @keydown.enter.native="handleRadioButtonInput('download-map-view-radio')"
            @keyup.enter.native="handleRadioButtonInput('download-map-view-radio')"
          >
            {{ $t('MAP_VIEW') }}
          </b-radio>
        </b-field>
      </div>
      <div class="row">
        <label class="label">{{ $t('DOWNLOAD_FILE_TYPE') }}</label>
        <b-field>
          <b-radio-button
            v-model="currentFormat"
            native-value="shp"
            tabindex="704"
            aria-label="Shapefile Radio Button"
            @keypress.enter.native="currentFormat = 'shp'"
          >
            <span>{{ $t('SHAPEFILE') }}</span>
          </b-radio-button>

          <b-radio-button
            v-model="currentFormat"
            native-value="geopackage"
            tabindex="705"
            aria-label="Geopackage Radio Button"
            @keypress.enter.native="currentFormat = 'geopackage'"
          >
            <span>{{ $t('GEOPACKAGE') }}</span>
          </b-radio-button>

          <b-radio-button
            v-model="currentFormat"
            native-value="kml"
            tabindex="706"
            aria-label="KML Radio Button"
            @keypress.enter.native="currentFormat = 'kml'"
          >
            {{ $t('KML') }}
          </b-radio-button>
          <b-radio-button
            v-model="currentFormat"
            native-value="csv"
            tabindex="707"
            aria-label="CSV Radio Button"
            @keypress.enter.native="currentFormat = 'csv'"
          >
            {{ $t('CSV') }}
          </b-radio-button>
        </b-field>
      </div>
      <div
        class="row"
      >
        <label class="label">{{ $t('DOWNLOAD_FEATURE_TYPE') }}</label>

        <div class="checkboxes-container">
          <b-radio
            v-model="layer"
            native-value="dams"
            tabindex="705"
            aria-label="Dams Layer Radio Button"
            class="download-dams-layer-radio"
            @input="handleRadioButtonInput('download-dams-layer-radio')"
          >
            {{ $t('DAMS') }}
          </b-radio>
          <b-radio
            v-model="layer"
            native-value="waterfalls"
            tabindex="705"
            aria-label="Waterfalls Layer Radio Button"
            class="download-waterfalls-layer-radio"
            @input="handleRadioButtonInput('download-waterfalls-layer-radio')"
          >
            {{ $t('WATERFALLS') }}
          </b-radio>
          <b-radio
            v-model="layer"
            native-value="fishways"
            tabindex="705"
            aria-label="Fishways Layer Radio Button"
            class="download-fishways-layer-radio"
            @input="handleRadioButtonInput('download-fishways-layer-radio')"
          >
            {{ $t('FISHWAYS') }}
          </b-radio>
          <b-radio
            v-model="layer"
            native-value="modelled_crossings"
            tabindex="705"
            aria-label="Modelled Crossings Layer Radio Button"
            class="download-modelled_crossings-layer-radio"
            @input="handleRadioButtonInput('download-modelled_crossings-layer-radio')"
          >
            {{ $t('MODELLED_CROSSINGS') }}
          </b-radio>
        </div>
      </div>
    </div>
    <div class="buttons-container has-text-centered">
      <!-- switch function to showUserInput = true when CAB ready to collect user data -->
      <b-button
        type="is-primary"
        tabindex="710"
        aria-label="Download Button"
        class="download-button"
        @click="showUserInput = true"
        @keypress.enter="showUserInput = true"
        >
        <!-- @click="download"
        @keypress.enter="download" -->
        {{ $t('DOWNLOAD') }}
      </b-button>

      <b-button
        tabindex="711"
        aria-label="Edit Filters Button"
        class="edit-filters-button"
        @click="editFilters"
        @keypress.enter="editFilters"
      >
        {{ $t('EDIT_FILTERS') }}
      </b-button>
    </div>
    <div>
      <p>
        {{ $t('DOWNLOAD_NOTE') }}
      </p>
    </div>
    <div
      v-if="showUserInput"
      class="user-input"
    >
      <b-input
        v-model="email"
        class="p-1"
        :placeholder="$t('PLACEHOLDER_EMAIL')"
        type="email"
      />
      <b-input
        v-model="fullname"
        class="p-1"
        :placeholder="$t('PLACEHOLDER_NAME')"
        type="text"
      />
      <b-input
        v-model="organization"
        class="p-1"
        :placeholder="$t('PLACEHOLDER_ORGANIZATION')"
        type="text"
      />
      <div v-if="!userSubbed" class="check-container my-2">
        <b-checkbox v-model="wantToSub" />
        {{ $t('CLICK_JOIN_MAIL_LIST') }}

        <b-tooltip
            :label="$t('MAIL_DISCLAIMER')"
            position="is-left"
            multilined
        >
            <b-icon
                pack="mdi"
                icon="information"
                class="ml-3 mt-1"
            />
        </b-tooltip>
      </div>
      <span v-else>
        <a @click="userSubbed = false">{{  $t('CLICK_TO_UNSUB') }}</a>
      </span>
      <div class="check-container my-2">
        <b-checkbox v-model="consent" />
        <p>{{ $t('CONSENT_MESSAGE') }} <a href="https://cwf-fcf.org/en/about-cwf/policies/privacy-policy.html" target="_blank">{{ $t('PRIVACY_POLICY') }}</a>.</p>
      </div>
      <p
        v-if="statusMessage"
        class="status-message mb-2"
      >
        {{ statusMessage }}
      </p>
      <b-button
        type="is-primary"
        aria-label="Download Button"
        class="submit-button"
        :loading="downloading"
        :disabled="!consent"
        @click="download"
        @keypress.enter="download"
      >
        {{ $t('SUBMIT') }}
      </b-button>
    </div>
  </b-sidebar>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash.debounce';

export default {
    name: 'DownloadSidebar',

    props: {
        filters: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        dataSet: 'filtered',
        layer: 'dams',
        currentFormat: 'shp',
        showSidebar: false,
        showUserInput: false,
        fullname: '',
        organization: '',
        email: '',
        statusMessage: '',
        wantToSub: false,
        userSubbed: false,
        consent: false,
        downloading: false,
    }),

    watch: {
        email() {
            this.checkEmail();
        }
    },

    methods: {
        /**
         * Check if email entered is on the mailing list
         */
        checkEmail: debounce(async function() {
            try {
                const res = await axios.get(`${this.$config.CABD_API}contacts?email=${this.email}`);
                if (res.status === 200) {
                    this.userSubbed = res.data.mailinglist;
                    this.wantToSub = res.data.mailinglist;
                }
            } catch (e) {
                console.error(e);
            }
        }, 300),
        /**
         * Builds API request URLs for data downloads.
         *
         * @returns {Array} - array of download URL strings
         */
        buildUrls() {
            // separate urls for different types will open in new tabs
            const downloads = [];
            const baseUrl = `${this.$config.CABD_API}features`;

            // base URL query parameters for all requests
            const params = new URLSearchParams();

            // "All Features": query all data for the selected layers
            if (this.dataSet === 'all') {
                // output format for all features
                params.append('format', this.currentFormat);

                // add an API request URL for each selected type
                const typeUrls = [`${baseUrl}/${this.layer}?${params}`];

                downloads.push(...typeUrls);
            } else {
                // "Currently Filtered": add filter parameters to API requests to match map the filters
                const { attributeFilters, locationFilters } = this.filters;

                // output format for filtered features
                params.append('format', this.currentFormat);

                // province/territory filter
                if (locationFilters.selectedProvinces?.length) {
                    // list of province codes
                    const provinces = locationFilters.selectedProvinces
                        .map((p) => p.value)
                        .join(',');
                    params.append('filter', `province_territory_code:in:${provinces}`);
                }

                // watershed filter
                if (locationFilters.selectedWatersheds?.length) {
                    // list of ids
                    const watersheds = locationFilters.selectedWatersheds
                        .map((w) => w.value)
                        .join(',');
                    params.append('filter', `nhn_watershed_id:in:${watersheds}`);
                }

                // check each feature type for active filters, and build API query URLs accordingly
                // build query string: add params for any active filters
                const typeParams = this.getFilterParams(attributeFilters[this.layer].subfilters, params);

                // build API request URL
                const url = `${baseUrl}/${this.layer}?${typeParams}`;
                downloads.push(url);
            }

            return downloads;
        },

        /**
         * Builds "filter" API query parameters for a set of filters.
         *
         * @param {Array} filters - individual feature type filters
         * @param {URLSearchParams} [baseParams] - if specified, query parameters will be appended to the ones supplied
         * @returns {URLSearchParams} - query parameters object
         */
        getFilterParams(filters, baseParams) {
            // add query params for any active filters
            const filterParams = new URLSearchParams(baseParams);

            // append filter parameters based on the filter type
            filters.forEach((filter) => {
                const { attributeName, type } = filter;

                // multiple selections: "in"
                if (type === 'checkbox') {
                    // list of filter values
                    const values = filter.values
                        .reduce((acc, curr) => {
                            if (curr.active) {
                                acc.push(curr.value);
                            }

                            return acc;
                        }, [])
                        .join(',');
                    filterParams.append('filter', `${attributeName}:in:${values}`);
                } else if (type === 'range') {
                  if (filter.filteredRange[0] !== filter.range[0] || filter.filteredRange[1] !== filter.range[1]) {
                    // min/max numerical values: "gte/lte"
                    const [min, max] = filter.filteredRange;
                    filterParams.append('filter', `${attributeName}:gte:${min}`);
                    filterParams.append('filter', `${attributeName}:lte:${max}`);
                  }
                } else if (type === 'boolean') {
                    // boolean values: "isnull/notnull"
                    const yes = filter.values.find((value) => value.active && value.name === 'Yes');
                    const no = filter.values.find((value) => value.active && value.name === 'No');

                    // if either option is unchecked, add a filter parameter for the other
                    if (!yes || !no) {
                        // "Yes" option: (not null / true)
                        if (yes) {
                            if (attributeName === 'use_analysis') {
                                filterParams.append('filter', `${attributeName}:eq:true`);
                            } else {
                                filterParams.append('filter', `${attributeName}:notnull:`);
                            }
                        }

                        // "No" option: (explicitly null / false)
                        if (no) {
                            if (attributeName === 'use_analysis') {
                                filterParams.append('filter', `${attributeName}:eq:false`);
                            } else {
                                filterParams.append('filter', `${attributeName}:isnull:`);
                            }
                        }
                    }
                }
            });

            return filterParams;
        },

        /**
         * Triggers downloads for the selected data sets.
         */
        async download() {
            // This function will want to receive user input before allowing them to download data
            if (this.fullname && this.email) {
                this.downloading = true;
                try {
                    const res = await axios.put(`${this.$config.CABD_API}contacts/`, {
                        name: this.fullname,
                        email: this.email,
                        organization: this.organization,
                        mailinglist: this.wantToSub,
                    });
                    if (res.status === 200) {
                        this.statusMessage = '';
                        // get request URLs and filenames
                        const downloads = this.buildUrls();
                        this.$emit('download-files', {
                            dataset: this.dataSet,
                            urls: downloads,
                        });
                        this.showUserInput = false;
                    } else {
                        this.statusMessage = this.$t('UPLOAD_FAILURE');
                    }
                } catch (e) {
                    this.statusMessage = this.$t('UPLOAD_FAILURE');
                }
                this.downloading = false;
            } else {
                this.statusMessage = this.$t('UPLOAD_MISSING');
            }
            this.fullname = '';
            this.email = '';
            this.organization = '';
        },

        /**
         * Closes this dialog and opens the "Edit Filters" dialog.
         */
        editFilters() {
            this.$emit('close');
            this.$emit('edit-filters');
        },

        /**
         * Handle a checkbox being updated. This will focus a checkbox based on the given class.
         *
        * This is to account for the fact that clicking on the checkbox behaves differently than tabbing to it and
         * pressing enter. A different element is actually focussed, and so it no longer follows the set tabindex
         * order.
         *
         * @param {string} checkboxClass - the class of the checkbox we want to focus.
         */
        handleCheckboxInput(checkboxClass) {
            const checkbox = document.querySelector(`label.b-checkbox.checkbox.${checkboxClass}`);
            setTimeout(() => {
                checkbox.focus();
            }, 50);
        },

        /**
         * Handle a radio button being updated. This will focus a radio button based on the given class.
         *
         * This is to account for the fact that clicking on the radio button behaves differently than tabbing to it and
         * pressing enter. A different element is actually focussed, and so it no longer follows the set tabindex
         * order.
         *
         * @param {string} radioButtonClass - the class of the radio button we want to focus.
         */
        handleRadioButtonInput(radioButtonClass) {
            const radioButton = document.querySelector(`label.b-radio.radio.${radioButtonClass}`);
            setTimeout(() => {
                radioButton.focus();
            }, 50);
        },

        toggleSidebar() {
            this.showSidebar = !this.showSidebar;
        },

        closeSidebar() {
            if (this.showSidebar) {
                this.showSidebar = false;
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss">

.privacy-text {
    font-size: 0.75em;
}

.check-container {
    align-items: center;
    display: flex;
}

.close-sidebar {
    cursor: pointer;
    position: absolute;
    right: 1em;
}

.download-sidebar {
    .sidebar-content {
        position: relative;
        background: $white;
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: auto 1fr auto;

        .downloads-container {
            position: relative;
            padding: 0;

            .download-option-group {
                margin: 1rem 0;

                .b-radio {
                    &:focus-visible {
                        .check {
                            border-color: $element-focus;
                        }
                    }

                    .control-label {
                        font-size: 1.5rem;
                    }
                }
            }

            .row {
                justify-content: space-between;
                align-items: center;

                .field .control {
                    outline: none;

                    &:focus-visible {
                        .button {
                            outline: 3px solid $element-focus;
                            border-color: transparent;
                            z-index: 10;
                        }
                    }
                }

                .checkboxes-container {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    grid-gap: 0.5rem;
                    width: 100%;
                    margin: 0.5rem 0;
                    margin-bottom: 1rem;

                    .checkbox:focus .check {
                        outline: 2px solid $element-focus;
                    }

                    // stylelint-disable-next-line no-descending-specificity
                    input:focus-visible + .check {
                        outline: 2px solid $element-focus;
                    }
                }

                .label {
                    margin: 0.5rem 0;
                }
            }
        }

        .modal-header {
            border-bottom: 1px solid $grey-light;
            padding: 0.5rem 0;
            margin-bottom: 0;
        }

        .buttons-container {
            display: grid;
            grid-template-columns: 1fr repeat(2, auto) 1fr;
            gap: 1rem;
            padding: 1.5rem 0;
            border-top: 1px solid $grey-lighter;

            // stylelint-disable-next-line no-descending-specificity
            .button:focus-visible {
                outline: 2px solid $element-focus;
            }

            .download-button {
                grid-column: 2/3;
            }

            .edit-filters-button {
                grid-column: 3/4;
            }
        }

        .close-button {
            position: absolute;
            top: 0;
            right: 0;
            color: $cabd-red;
            cursor: pointer;
            box-shadow: none;
            border: none;
            outline: none;
        }

        .show-close-button-focus {
            position: absolute;
            top: 5px;
            right: 5px;
            height: 1.75rem;
            width: 1.75rem;
            border: 2px solid transparent;
            pointer-events: none;
        }

        .close-button:focus-visible + .show-close-button-focus {
            border-color: $element-focus;
        }

        .close-button:hover + .show-close-button-focus {
            border-color: #aaa;
            border-radius: 3px;
        }
    }
}

.download-sidebar.b-sidebar > .sidebar-content {
    left: 15rem;
    width: 23rem;
    padding: 1rem;
}

.submit-button {
    width: 100%;
}

.status-message {
    border-radius: 0.5em;
    padding: 1em;
    background-color: pink;
    border: 1px solid $cabd-red;
}
</style>
