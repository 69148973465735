import scssVars from '@/assets/scss/variables.module.scss';

export const id = 'cabd-water';

export const source = {
    id,
    url: 'https://chyf-web.azurewebsites.net/chyf-web/tiles/water/{z}/{x}/{y}.mvt',
};

// fill layer
export const waterbodyLayer = {
    id: 'cabd-waterbody',
    type: 'fill',
    source: source.id,
    'source-layer': 'default',
    paint: {
        'fill-color': scssVars.hydroWaterbody,
        'fill-opacity': 0,
    },
    filter: ['==', 'type', 'waterbody'],
};

// line layer
// export const flowPathLayer = {
//     id: 'cabd-flowpath',
//     type: 'line',
//     source: source.id,
//     'source-layer': 'default',
//     paint: {
//         'line-color': scssVars.hydroFlowpath,
//         'line-opacity': 0,
//         'line-width': 1,
//     },
//     filter: ['==', 'type', 'flowpath'],
// };

export const layers = [
    waterbodyLayer,
    // flowPathLayer,
];
