/**
 * Global error handler; shows a Buefy snackbar toast on screen
 * @param  {Error}   err              Application error
 * @param  {String}  message          message to display to the user
 * @param  {Boolean} [critical=false] use for fatal errors where the app must be reloaded
 */
function handleError(err, message, critical = false) {
    console.error('Error:', err, message); // eslint-disable-line no-console
    const snackbarOptions = {
        message,
        type: 'is-danger',
    };
    // for critical errors, refresh the page after dismissing snackbar
    if (critical) {
        snackbarOptions.indefinite = true;
        snackbarOptions.onAction = () => { this.$router.go(); };
        snackbarOptions.actionText = 'Retry';
    }
    this.$buefy.snackbar.open(snackbarOptions);
}

// install as a global mixin
export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                $handleError: handleError,
            },
        });
    },
};
