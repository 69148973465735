import scssVars from '@/assets/scss/variables.module.scss';

export const id = 'cabd-nhn-work-units';

export const source = {
    id,
    url: 'https://chyf-web.azurewebsites.net/chyf-web/tiles/nhnworkunit/{z}/{x}/{y}.mvt',
};

// work unit polygons. Transparent as we just use them to know if the user has clicked within the area
export const fillLayer = {
    id: `${id}-fill`,
    type: 'fill',
    source: source.id,
    'source-layer': 'default',
    paint: {
        'fill-opacity': 0,
    },
};

// work unit borders, as lines
export const outlineLayer = {
    id: `${id}-outline`,
    type: 'line',
    source: source.id,
    'source-layer': 'default',
    paint: {
        'line-color': scssVars.nhnWorkUnitOutline,
        'line-width': 2,
        'line-opacity': 0, // initially invisible
    },
};

// work unit borders, as highlighted lines
export const highlightLayer = {
    id: `${id}-highlight`,
    type: 'line',
    source: source.id,
    'source-layer': 'default',
    paint: {
        'line-color': scssVars.nhnWorkUnitHighlight,
        'line-width': 2,
        'line-opacity': 1,
    },
    filter: ['==', 'id', 'never-going-to-match'], // all hidden by filter initially
};

export const layers = [
    fillLayer,
    outlineLayer,
    highlightLayer,
];
