/**
 * default options for this class
 */
const _OPTIONS = {
    maxWidth: 100,
};

/**
 * Custom locator control with a scale element. I didn't make this a Vue component because I'm not sure how to expose
 * IControl required values such as onAdd and onRemove
 *
 * @see MapboxGL.IControl <https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol>
 */
class LocatorControl {
    /**
     * Create a LocatorControl object, initialize with options
     * @param {Object} options A set of options to override the defaults for this Locator Control.
     */
    constructor(options = {}) {
        this._options = {};
        Object.keys(_OPTIONS).forEach((opt) => {
            this._options[opt] = options[opt] || _OPTIONS[opt];
        });
    }

    /**
     * build up the element when it's added to the map - I don't think I have enough time to figure out how to make this
     * a vue component but that's probably something we should do in the future.
     *
     * @param {Object} map a MapboxGL JS map object.
     */
    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-locator';
        this._first = document.createElement('span');
        this._first.className = 'coords hidden';
        this._last = document.createElement('span');
        this._last.className = 'coords hidden';
        this._scale = document.createElement('span');
        this._scaleText = document.createElement('span');
        this._scaleText.textContent = '';
        this._scale.style.display = 'inline-block';
        this._container.appendChild(this._first);
        this._container.appendChild(this._last);
        this._container.appendChild(this._scaleText);
        this._container.appendChild(this._scale);
        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        // eslint-disable-next-line no-undefined
        this._map = undefined;
    }

    setCoordTexts(coords) {
        [this._first.textContent, this._last.textContent] = coords;
        this._first.className = 'coords';
        this._last.className = 'coords';
    }

    setScaleValues(width, scaleText) {
        this._scale.style.width = width;
        this._scaleText.textContent = scaleText;
    }

    /**
     * Updates the scale for the locator control - copied from
     * <https://github.com/mapbox/mapbox-gl-js/blob/d2f1eea030568d421906e31f64bb8ad2803ae135/src/ui/control/scale_control.js>
     * and slightly modified to suit our needs - we don't need imperial or nautical scales for instance.
     */
    updateScale() {
    // A horizontal scale is imagined to be present at center of the map
    // container with maximum length (Default) as 100px.
    // Using spherical law of cosines approximation, the real distance is
    // found between the two coordinates.

        const { maxWidth } = this._options;

        const y = this._map._container.clientHeight / 2;
        const left = this._map.unproject([0, y]);
        const right = this._map.unproject([maxWidth, y]);
        const maxMeters = left.distanceTo(right);
        // The real distance corresponding to 100px scale length is rounded off to
        // near pretty number and the scale length for the same is found out.
        if (maxMeters >= 1000) {
            this.setScale(maxWidth, maxMeters / 1000, 'km');
        } else {
            this.setScale(maxWidth, maxMeters, 'm');
        }
    }

    /**
     * helper function for setting the scale width and text
     *
     * @param {Number} maxWidth the maximum width of the scale ruler item, in pixels
     * @param {Number} maxDistance a distance derived from a map measurement
     * @param {String} unit the unit to measure in
     */
    setScale(maxWidth, maxDistance, unit) {
        const distance = this.getRoundNum(maxDistance);
        const ratio = distance / maxDistance;
        this.setScaleValues(`${maxWidth * ratio}px`, `${distance} ${unit} `);
    }

    /**
     * select a nice number for showing on a scale
     *
     * @param {Number} num the number to round to a specific value.
     * @return A number like 1000, 500, 30, etc.
     */
    getRoundNum(num) {
        const pow10 = 10 ** ((`${Math.floor(num)}`).length - 1);
        let d = num / pow10;
        const limits = [10, 5, 3, 2, 1];
        d = limits.find((limit) => d >= limit);
        // d can be undefined if all the limits are larger, this happens when we zoom in so far
        // that the scale defined with the maxwidth is under a meter.
        if (!d) {
            d = this.getDecimalRoundNum(d);
        }
        return pow10 * d;
    }

    /**
     * Gets a nice rounded decimal number - so if you supply it something like 0.3948284827482 you get 0.4.
     * I got this from mapbox's scale control source
     *
     * @param {Number} d the decimal value you want rounded
     *
     * @returns a rounded decimal value.
     */
    static getDecimalRoundNum(d) {
        const multiplier = 10 ** (Math.ceil(-Math.log(d) / Math.LN10));
        return Math.round(d * multiplier) / multiplier;
    }
}

export default LocatorControl;
