/**
 * default options for this class
 */
const _OPTIONS = {
    actions: [],
    switcherSize: 20,
};

/**
 * Custom switch list for a switcher control, that runs some code and "selects" a
 * switch when the user clicks it
 *
 * @see MapboxGL.IControl <https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol>
 */
class SwitcherControl {
    /**
     * Create a SwitcherControl
     * @param {Object} options A set of options to override the defaults for this Style Switcher Control.
     */
    constructor(options = {}) {
        this._selected = 0;
        this._options = {};
        Object.keys(_OPTIONS).forEach((opt) => {
            this._options[opt] = options[opt] || _OPTIONS[opt];
        });
    }

    /**
     * build up the element when it's added to the map - uses an SVG for a background image
     *
     * @param {Object} map a MapboxGL JS map object.
     */
    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapbox-ctrl-group mapboxgl-ctrl mapboxgl-ctrl-style-switcher';
        this._selectors = [];
        const _this = this;

        // go through each of the "actions" and ensure there's an interface element for each
        // use the actions colours or the defaults if there are none
        this._options.actions.forEach((action, idx) => {
            const selector = document.createElement('span');
            selector.className = 'style-switcher';
            selector.tabIndex = 0;
            if (action.classes) {
                selector.className = [selector.className, ...action.classes].join(' ');
            }
            if (action.onclick) {
                selector.onclick = () => {
                    if (_this.selected !== idx) {
                        _this.selectButton(idx);
                        action.onclick();
                    }
                };
                selector.addEventListener('keypress', (event) => {
                    if (event.code === 'Enter' && _this.selected !== idx) {
                        _this.selectButton(idx);
                        action.onclick();
                    }
                });
            }

            // label for screen readers
            if (action.label) {
                selector['aria-label'] = action.label;
            }

            // set the width and height to the button size and give some extra for a 2px border;
            selector.style.setProperty('width', `${this._options.switcherSize + 4}px`);
            selector.style.setProperty('height', `${this._options.switcherSize + 4}px`);

            // default colors, green and reddish
            const innerColors = ['rgb(172, 212, 172)', 'rgb(255, 250, 228)'];
            if (action.colors.length === 2) {
                // set to the colors defined in action.colors
                [innerColors[0], innerColors[1]] = action.colors;
            }
            // build up a background with svg using a NS
            const xmlns = 'http://www.w3.org/2000/svg';
            // if you don't use NS this won't work
            const svg = document.createElementNS(xmlns, 'svg');
            svg.setAttributeNS(null, 'viewBox', '0 0 30 30');
            svg.setAttributeNS(null, 'width', 30);
            svg.setAttributeNS(null, 'height', 30);
            svg.style.display = 'inline-block';
            const g = document.createElementNS(xmlns, 'g');
            // upper triangle
            const path1 = document.createElementNS(xmlns, 'path');
            path1.setAttributeNS(null, 'd', 'M 30,30 0,0 h 30 z');
            path1.style.setProperty('fill', innerColors[1]);
            g.appendChild(path1);
            // lower triangle
            const path2 = document.createElementNS(xmlns, 'path');
            path2.setAttributeNS(null, 'd', 'M 0,0 30,30 H 0 Z');
            path2.style.setProperty('fill', innerColors[0]);
            g.appendChild(path2);
            // slash across the middle from upper left to bottom right
            const path3 = document.createElementNS(xmlns, 'path');
            path3.setAttributeNS(null, 'd', 'M 0,0 30,30');
            path3.style.setProperty('stroke', 'rgb(0,0,0)');
            path3.style.setProperty('stroke-width', 1);
            g.appendChild(path3);
            svg.appendChild(g);
            // gotta serialize it, encode, unescape, then convert to base64
            // another way might work but this took me way too long to figure out
            const serialized = window.btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(svg))));
            selector.style.setProperty('background', `url('data:image/svg+xml;base64,${serialized}')`);

            // keep track of the button too so we can just refer to it in an array.
            this._selectors.push(selector);
            this._container.appendChild(selector);
        });

        // select the first button initially.
        this.selectButton(0);
        return this._container;
    }

    /**
     * Set the action at a specified index as currently selected.
     *
     * @param {Number} index The index of the action we wish to have "selected" - starts at 0
     */
    selectButton(index) {
        this._selected = index;
        this._selectors.forEach((selector, idx) => {
            if (idx === index) {
                selector.classList.add('selected');
            } else {
                selector.classList.remove('selected');
            }
        });
    }

    /**
     * required function for an MapboxGl IControl
     *
     * @see MapboxGL.IControl <https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol>
     */
    onRemove() {
        this._container.parentNode.removeChild(this._container);
        // eslint-disable-next-line no-undefined
        this._map = undefined;
    }
}

export default SwitcherControl;
