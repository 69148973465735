<template>
  <div class="map-view page">
    <div class="cwf-logo">
      <img
        v-if="$i18n.locale === 'en'"
        src="@/assets/cwf_logo_english.svg"
        alt="Canadian Wildlife Federation - Logo"
      >
      <img
        v-else
        src="@/assets/cwf_logo_french.svg"
        alt="Fédération Canadienne de la Faune - Logo"
      >
    </div>
    <div
      id="map-container"
      role="main"
      aria-label="Map"
    >
      <mapbox-gl-map
        :options="getMapOptions()"
        zoom-control
        scale-and-coordinates
        :style-switch-options="styles"
        @load="mapLoaded"
        @click="handleMapClick"
        @mousemove="handleMouseMove"
      />
      <watershed-name
        :watershed="watershedName"
      />
      <map-legend
        :show.sync="showLegend"
        :accessibility-mode="accessibilityMode"
      />
      <div
        v-show="loadingMessage"
        class="sources-loading"
      >
        <div class="loading-text">
          {{ (loadingMessage === true)? 'Loading' : loadingMessage }} <span class="loader" />
        </div>
      </div>
    </div>
    <filter-sidebar
      ref="filterSidebar"
      role="sidebar"
      aria-label="Filter Sidebar"
      :location-filters="locationFilters"
      :attribute-filters="attributeFilters"
      :default-attribute-filters="defaultAttributeFilters"
      :feature-type-definitions="featureTypeDefinitions"
      :feature-count="filteredFeatureCount"
      :total-count="featureCount"
      @close="closeFiltersSidebar"
      @apply-filters="applyFilters"
      @nhn="applyNhn"
      @reset-filters="resetFilters"
      @download-data="toggleDownloadSidebar"
    />
    <download-sidebar
      ref="downloadSidebar"
      role="sidebar"
      aria-label="Downloads Sidebar"
      :filters="{
        locationFilters,
        attributeFilters,
      }"
      @close="closeDownloadSidebar"
      @edit-filters="toggleFiltersSidebar"
      @download-files="(opts) => downloadFiles(opts)"
    />
    <sidebar
      role="complementary"
      aria-label="Sidebar"
      :attribute-filters="attributeFilters"
      @location-searched="goToLocation"
      @feature-select="featureSelect"
      @layers-updated="applyFilters"
      @accessibility-mode-toggled="(aMode) => {accessibilityMode = aMode}"
      @open-filters="toggleFiltersSidebar"
      @open-downloads="toggleDownloadSidebar"
      @close="closeMainSidebar"
    />
    <map-popup
      role="dialog"
      aria-label="Map Popup"
      :selected-feature="selectedFeature || {}"
      :show-popup="showPopup"
      :feature-types="featureTypes"
      :feature-type-definitions="featureTypeDefinitions"
      :expand-sidebar="expandSidebar"
      @close="showPopup = false"
    />
  </div>
</template>

<script>
// JS libs
import axios from 'axios';
import isEmpty from 'lodash.isempty';

// deck gl stuff
import { MapboxOverlay } from '@deck.gl/mapbox';
import { GeoJsonLayer } from '@deck.gl/layers';
import A11yCompositeLayer from '@/helpers/A11yCompositeLayer';

// Constants
import scssVars from '@/assets/scss/variables.module.scss';

// Layer definitions etc.
import * as nhnWorkUnitLayers from '@/constants/mapLayers/nhnWorkUnits';
import * as waterbodyLayers from '@/constants/mapLayers/waterbodies';
import * as modelledCrossingsLayers from '@/constants/mapLayers/modelledCrossings';

// Components
import DownloadSidebar from '@/components/DownloadSidebar.vue';
import MapLegend from '@/components/MapLegend.vue';
import MapPopup from '@/components/MapPopup.vue';
import Sidebar from '@/components/Sidebar.vue';
import FilterSidebar from '@/components/FilterSidebar.vue';
import WatershedName from '@/components/WatershedName.vue';

// mixins
import mapping from '@/mixins/mapping';

// convert colours in scss to arrays of the form [RR, GG, BB] for deck.gl
// assumes it's in hex like #rrggbb
const deckColors = {
    partial: scssVars.statusPartial.replace('#', '').match(/.{1,2}/g).map((x) => parseInt(x, 16)),
    barrier: scssVars.statusBarrier.replace('#', '').match(/.{1,2}/g).map((x) => parseInt(x, 16)),
    passable: scssVars.statusPassable.replace('#', '').match(/.{1,2}/g).map((x) => parseInt(x, 16)),
    unknown: scssVars.statusUnknown.replace('#', '').match(/.{1,2}/g).map((x) => parseInt(x, 16)),
    fishways: scssVars.fishways.replace('#', '').match(/.{1,2}/g).map((x) => parseInt(x, 16)),
    naNoStructure: scssVars.statusNone.replace('#', '').match(/.{1,2}/g).map((x) => parseInt(x, 16)),
    naRemoved: scssVars.statusRemoved.replace('#', '').match(/.{1,2}/g).map((x) => parseInt(x, 16)),
};

const firstLabelLayerId = 'building-number-label';

export default {
    name: 'MapView',

    components: {
        DownloadSidebar,
        MapLegend,
        Sidebar,
        MapPopup,
        FilterSidebar,
        WatershedName,
    },

    mixins: [
        mapping,
    ],

    data() {
        return {
            styles: [
                {
                    label: 'Switch to Base Style',
                    onclick: () => {
                        this.map.setPaintProperty('mapbox-satellite', 'raster-opacity', 0);
                    },
                    colors: ['#e7ebe2', '#dae6ca'],
                },
                {
                    label: 'Switch to Satellite Style',
                    onclick: () => {
                        this.map.setPaintProperty('mapbox-satellite', 'raster-opacity', 1);
                    },
                    colors: ['#14202d', '#314720'],
                },
            ],
            loadingMessage: 'Loading Barrier Data',

            map: null,

            showPopup: false,
            expandSidebar: false,

            gettingFeature: null,
            gettingExport: 0,
            selectedFeature: null,
            watershedName: '',

            csvLoading: false,
            // list of visible barrier point feature layers
            featureTypes: [],
            featureTypeDefinitions: {},

            accessibilityMode: false,

            showLegend: true,

            locationFilters: {
                locationType: 'province',
                regions: [],
                selectedProvinces: [],
                selectedWatersheds: [],
            },
            attributeFilters: {},
            defaultAttributeFilters: {},

            dams: {},
            fishways: {},
            waterfalls: {},
            modelled_crossings: {},

            damsFiltered: {},
            fishwaysFiltered: {},
            waterfallsFiltered: {},
            crossingsFiltered: {},

            damsLayer: {},
            waterfallsLayer: {},
            fishwaysLayer: {},
            crossingsLayer: {},

            damsA11yLayer: {},
            waterfallsA11yLayer: {},
            fishwaysA11yLayer: {},
            crossingsA11yLayer: {},

            deckOverlay: null,
            hovering: false,
        };
    },

    computed: {
        featureCount() {
            const featureCount = {
                dams: -1,
                waterfalls: -1,
                fishways: -1,
                modelled_crossings: -1,
            };
            if (this.dams.features) {
                featureCount.dams = this.dams.features.length;
            }
            if (this.waterfalls.features) {
                featureCount.waterfalls = this.waterfalls.features.length;
            }
            if (this.fishways.features) {
                featureCount.fishways = this.fishways.features.length;
            }
            if (this.modelled_crossings.features) {
                featureCount.modelled_crossings = this.modelled_crossings.features.length;
            }
            return featureCount;
        },
        filteredFeatureCount() {
            const featureCount = {
                dams: -1,
                waterfalls: -1,
                fishways: -1,
                modelled_crossings: -1,
            };
            if (this.damsFiltered.features) {
                featureCount.dams = this.damsFiltered.features.length;
            }
            if (this.waterfallsFiltered.features) {
                featureCount.waterfalls = this.waterfallsFiltered.features.length;
            }
            if (this.fishwaysFiltered.features) {
                featureCount.fishways = this.fishwaysFiltered.features.length;
            }
            if (this.crossingsFiltered.features) {
                featureCount.modelled_crossings = this.crossingsFiltered.features.length;
            }
            return featureCount;
        },
    },

    watch: {
        featureTypeDefinitions() {
            this.buildAttributeFilters();
        },
        async accessibilityMode() {
            await this.setAccessibilityMode();
        },
    },

    async created() {
    // get all the feature information
        this.loadingMessage = this.$t('LOADING_TYPE');
        const featureTypeDefinitions = {};
        // for each of the types, get the feature array and description
        try {
            this.featureTypes = (await axios.get(`${this.$config.CABD_API}features/types/`)).data;
            const headers = {};
            if (this.$i18n.locale === 'fr') {
                headers['Accept-Language'] = 'fr,fr-FR;q=0.8,en-US;q=0.5,en;q=0.3';
            } else {
                headers['Accept-Language'] = 'en-US, en;q=0.9';
            }
            const results = await Promise.all(this.featureTypes.map((type) => axios.get(`${this.$config.CABD_API}features/types/${type.type}`, {headers})));
            this.featureTypes.forEach((type, index) => {
                featureTypeDefinitions[type.type] = results[index].data;
            });

            this.featureTypeDefinitions = JSON.parse(JSON.stringify(featureTypeDefinitions));
            this.buildAttributeFilters();
        } catch (e) {
            this.loadingMessage = false;
            this.$handleError(e, 'Error retrieving feature types');
        }
    },

    methods: {
        /**
         * Stuff to do once the map is loaded, like add some tiles from the CABD tileservers
         *
         * @param {Object} ev - the event, not used
         * @param {Object} map - MapboxGL js map object
         */
        async mapLoaded(ev, map) {
            this.map = map;

            // so - this needs to be here instead of created() because we
            // need to ensure this data is there for usin addLayers

            this.loadingMessage = this.$t('LOADING_POINTS');

            const dataPromises = [];
            dataPromises.push({
                title: 'dams',
                promise: axios.get(`${this.$config.CABD_API}features/dams?attributes=limited`),
            });
            dataPromises.push({
                title: 'waterfalls',
                promise: axios.get(`${this.$config.CABD_API}features/waterfalls?attributes=limited`),
            });
            dataPromises.push({
                title: 'fishways',
                promise: axios.get(`${this.$config.CABD_API}features/fishways?attributes=limited`),
            });
            // dataPromises.push({
            //     title: 'modelled_crossings',
            //     promise: axios.get(`${this.$config.CABD_API}features/modelled_crossings?attributes=limited`),
            // });
            dataPromises.push({
                title: 'modelled_crossings',
                promise: axios.get(`${this.$config.CABD_API}features/modelled_crossings?max-results=0`),
            });
            if (dataPromises.length) {
                try {
                    const results = await Promise.all(dataPromises.map((p) => p.promise));

                    dataPromises.forEach((p, idx) => {
                        this[p.title] = results[idx].data;
                    });
                    this.damsFiltered = this.dams;
                    this.waterfallsFiltered = this.waterfalls;
                    this.fishwaysFiltered = this.fishways;
                    this.crossingsFiltered = this.modelled_crossings;
                    this.addLayers();
                } catch (e) {
                    this.loadingMessage = false;
                    this.$handleError(e, 'Error(s) retrieving barrier points');
                }
            }
        },

        /**
         * Add the CABD layers from the chyf-web implementation - needs to be called again every time
         * the style changes.
         */
        addLayers() {
            if (this.map) {
                // add waterbody feature layers
                this.addWaterbodyLayers();

                // add NHN Work Unit layer(s)
                this.addNhnWorkUnitLayers();

                // add barrier point feature layers
                this.addBarrierPointLayers();

                // add modelled crossings feature layer
                this.addModelledCrossingsLayers();

                this.loadingMessage = false;
            }
        },

        /**
         * Adds waterbody data source and feature layers.
         */
        addWaterbodyLayers() {
            const { layers, source } = waterbodyLayers;

            // add source
            if (!this.map.getSource(source.id)) {
                this.loadingMessage = this.$t('ADDING_WATERBODY');
                this.map.addSource(source.id, {
                    type: 'vector',
                    tiles: [source.url],
                });
            }

            // add layer barrier point feature layers
            layers.forEach((layer) => {
                if (!this.map.getLayer(layer.id)) {
                    this.map.addLayer(layer, firstLabelLayerId);
                }
            });
        },

        addModelledCrossingsLayers() {
            const { mcLayer, source, mcA11yLayer, mcTextLayer } = modelledCrossingsLayers;

            // add source
            if (!this.map.getSource(source.id)) {
                this.loadingMessage = this.$t('ADDING_MODELLED_CROSSING');
                this.map.addSource(source.id, {
                    type: 'vector',
                    tiles: [source.url],
                });
            }

            // add layer barrier point feature layers
            if (!this.map.getLayer(mcLayer.id)) {
                this.map.addLayer(mcLayer, firstLabelLayerId);
            }

            if (!this.map.getLayer(mcA11yLayer.id)) {
                this.map.addLayer(mcA11yLayer, firstLabelLayerId);
                this.map.setLayoutProperty(mcA11yLayer.id, 'visibility', 'none');
            }

            if (!this.map.getLayer(mcTextLayer.id)) {
                this.map.addLayer(mcTextLayer, firstLabelLayerId);
                this.map.setLayoutProperty(mcTextLayer.id, 'visibility', 'none');
            }

            this.map.on('mouseenter', ['cabd-modelled-crossings', 'cabd-modelled-crossings-a11y'], () => {
                this.hovering = true;
            });

            this.map.on('mouseleave', ['cabd-modelled-crossings', 'cabd-modelled-crossings-a11y'], () => {
                this.hovering = false;
            });
        },

        /**
         * Adds barrier points data source and feature layers.
         */
        async addBarrierPointLayers() {
            // geojson layer template - we use this multiple times for the different geojson sources
            const geoJsonTemplate = {
                pointType: 'text',
                // https://pictogrammers.github.io/@mdi/font/6.9.96/
                // getTextSize: 2000,
                textSizeMinPixels: 6,
                textSizeMaxPixels: 24,
                textSizeScale: 10,
                textSizeUnits: 'meters',
                textFontFamily: 'Material Design Icons',
                textFontSettings: {
                    sdf: true,
                },
                textOutlineColor: [255, 255, 255],
                textOutlineWidth: 10,
                pickable: true,
                onClick: (info) => this.handleDeckClick(info.object?.properties?.cabd_id),
                getTextColor: (f) => {
                    if (f.properties.passability_status_code === 2) {
                        // partial barrier
                        return deckColors.partial;
                    }
                    if (f.properties.passability_status_code === 1) {
                        // barrier
                        return deckColors.barrier;
                    }
                    if (f.properties.passability_status_code === 3) {
                        // passable
                        return deckColors.passable;
                    }
                    if (f.properties.passability_status_code === 5) {
                        // passable
                        return deckColors.naNoStructure;
                    }
                    if (f.properties.passability_status_code === 6) {
                        // passable
                        return deckColors.naRemoved;
                    }
                    return deckColors.unknown;
                },
                visible: true,
                beforeId: firstLabelLayerId,
            };

            this.damsLayer = new GeoJsonLayer({
                id: 'cabd-dams',
                data: this.damsFiltered,
                ...geoJsonTemplate,
                getText: () => '\u{F0764}', // square
                textCharacterSet: ['\u{F0764}'], // square
            });
            this.waterfallsLayer = new GeoJsonLayer({
                id: 'cabd-waterfalls',
                data: this.waterfallsFiltered,
                ...geoJsonTemplate,
                getText: () => '\u{F0536}', // triangle
                textCharacterSet: ['\u{F0536}'], // triangle
            });
            this.fishwaysLayer = new GeoJsonLayer({
                id: 'cabd-fishways',
                data: this.fishwaysFiltered,
                ...geoJsonTemplate,
                getText: () => '\u{F023A}', // fish
                getTextColor: deckColors.fishways,
                textCharacterSet: ['\u{F023A}'], // fish
            });

            this.damsA11yLayer = new A11yCompositeLayer({
                id: 'cabd-a11y-dams',
                data: this.damsFiltered,
                pickable: true,
                onClick: (info) => this.handleDeckClick(info.object?.properties?.cabd_id),
                visible: false,
                getText: () => '\u{F0764}', // square
                textCharacterSet: ['\u{F0764}'], // square
                getBottomText: () => '\u{F0763}', // square outline
                bottomTextCharacterSet: ['\u{F0763}'], // square outline
                beforeId: firstLabelLayerId,
            });
            this.waterfallsA11yLayer = new A11yCompositeLayer({
                id: 'cabd-a11y-waterfalls',
                data: this.waterfallsFiltered,
                pickable: true,
                onClick: (info) => this.handleDeckClick(info.object?.properties?.cabd_id),
                visible: false,
                getText: () => '\u{F0536}', // triangle
                textCharacterSet: ['\u{F0536}'], // triangle
                getBottomText: () => '\u{F0537}', // triangle outline
                bottomTextCharacterSet: ['\u{F0537}'], // triangle outline
                beforeId: firstLabelLayerId,
            });
            this.fishwaysA11yLayer = new A11yCompositeLayer({
                id: 'cabd-a11y-fishways',
                data: this.fishwaysFiltered,
                pickable: true,
                onClick: (info) => this.handleDeckClick(info.object?.properties?.cabd_id),
                visible: false,
                getText: () => '\u{F023A}', // fish
                textCharacterSet: ['\u{F023A}'], // fish
                getBottomText: () => '\u{F023A}', // fish. There is no outline available
                bottomTextCharacterSet: ['\u{F023A}'], // fish. There is no outline available
                beforeId: firstLabelLayerId,
            });

            this.deckOverlay = new MapboxOverlay({
                // show the pointer mouse cursor when you hover over a feature.
                getCursor: ({ isHovering }) => (isHovering || this.hovering ? 'pointer' : 'grab'),
                interleaved: true,
                layers: [
                    this.damsLayer,
                    this.waterfallsLayer,
                    this.fishwaysLayer,
                    this.damsA11yLayer,
                    this.waterfallsA11yLayer,
                    this.fishwaysA11yLayer,
                ],
            });

            this.map.addControl(this.deckOverlay);
        },

        /**
         * update the barrier point layers with the new layers that have been modified from a filter.
         */
        updateBarrierPointLayers() {
            if (this.accessibilityMode) {
                this.deckOverlay.setProps({
                    layers: [
                        this.damsA11yLayer,
                        this.waterfallsA11yLayer,
                        this.fishwaysA11yLayer,
                        // this.crossingsA11yLayer,
                    ],
                });
            } else {
                this.deckOverlay.setProps({
                    layers: [
                        this.damsLayer,
                        this.waterfallsLayer,
                        this.fishwaysLayer,
                        // this.crossingsLayer,
                    ],
                });
            }
        },

        /**
         * Adds NHN Work Unit data source and feature layer(s).
         */
        addNhnWorkUnitLayers() {
            const { layers, source } = nhnWorkUnitLayers;

            // add source
            if (!this.map.getSource(source.id)) {
                this.loadingMessage = this.$t('ADDING_NHN');
                this.map.addSource(source.id, {
                    type: 'vector',
                    tiles: [source.url],
                });
            }

            // add layer barrier point feature layers
            layers.forEach((layer) => {
                if (!this.map.getLayer(layer.id)) {
                    this.map.addLayer(layer, firstLabelLayerId);
                }
            });
        },

        /**
         * Handle a mapbox click, separate from the deck.gl click - we need to have a semaphore used between them
         * which is this.gettingFeature. If a user has clicked on a feature, ignore all mapbox clicks.
         *
         * There is _supposed_ to be a way to stop the click bubbling through from deck.gl but it doesn't seem to work
         * as of deck.gl v. 8.8.9
         */
        handleMapClick(ev) {
            // only handle the watershed click when the sidebar is opened and the user isn't clicking on a
            // non-watershed feature.
            const feature = this.map.queryRenderedFeatures(ev.point, { layers: ['cabd-modelled-crossings']})[0];
            if (feature) {
                this.handleDeckClick(feature?.properties?.cabd_id);
            }
            else if (!this.gettingFeature && (this.$refs.filterSidebar.sidebarOpened() || this.attributeFilters['nhn-work-units'].active)) {
                // NHD Watershed feature maybe!
                const [feature] = this.map.queryRenderedFeatures(ev.point, {
                    layers: [nhnWorkUnitLayers.fillLayer.id],
                });
                // if the feature is a NHN Work Unit, we toggle the filter for that unit and return
                if (feature?.layer?.id === nhnWorkUnitLayers.fillLayer.id) {
                    this.toggleNhnWorkUnitFilter(feature.properties);
                }
            }
        },

        handleMouseMove(ev) {
            const [feature] = this.map.queryRenderedFeatures(ev.point, { layers: [nhnWorkUnitLayers.fillLayer.id] });
            if (typeof feature === 'object') {
                this.watershedName = `${feature.properties[`name_${this.$i18n.locale}`]} (${feature.properties.id})`;
            } else {
                this.watershedName = '';
            }
        },

        /**
         * When a deck.gl layer is clicked, retrieve any feature that was clicked or show no data message to user.
         *
         * @param {Object} ev - click event
         */
        async handleDeckClick(featureId) {
            this.showPopup = false;
            this.selectedFeature = null;
            let hadFeature = false;
            try {
                // get feature information using id
                if (featureId) {
                    this.gettingFeature = true;
                    this.loadingMessage = this.$t('LOAD_FEATURE_INFO');
                    const { data } = await axios.get(`${this.$config.CABD_API}features/${featureId}`);
                    this.selectedFeature = data;
                    hadFeature = true;
                }
            } catch (e) {
                this.gettingFeature = false;
                hadFeature = false;
                this.$handleError(e, 'Error retrieving feature');
            } finally {
                this.gettingFeature = false;
                this.showPopup = true;
                this.loadingMessage = false;
            }
            return hadFeature;
        },

        /**
         * Move map view to the given coordinates.
         *
         * @param {LngLatLike} coordinates - location to center map on
         */
        goToLocation({ coordinates, boundingBox }) {
            if (coordinates) {
                this.map.flyTo({ center: coordinates, zoom: 14, offset: [0, -100] });
            } else if (boundingBox) {
                this.map.fitBounds(boundingBox, { padding: 30 });
            }
        },

        /**
         * When a user clicks on a feature from a search function, go to the feature and select it
         *
         * @param {GeoJson} feature - an expected single geojson feature object with geometry.coordinates.
         */
        featureSelect(feature) {
            this.goToLocation({ coordinates: feature?.geometry?.coordinates });
            // deck.gl gives a weird sort of feature when you click on one so we're minimally replicating that here.
            this.handleDeckClick(feature?.properties?.cabd_id);
        },

        /**
         * Exports the current view as a downloadable CSV file
         */
        async downloadFiles(opts) {
            // given the parameters, we may have a different data set.
            // if filtered, use the filters, but don't apply map bounds
            // if map view, use the filters and map view
            // if all, apply none.
            const {
                dataset,
                urls,
            } = opts;

            const bbox = this.map.getBounds();
            const bboxParam = `${bbox._ne.lng},${bbox._ne.lat},${bbox._sw.lng},${bbox._sw.lat}`;

            let additionalParams = '';
            if (dataset === 'mapview') {
                // add the bbox to each query
                additionalParams = `&bbox=${bboxParam}`;
            }

            // download URLS, optionally with bbox
            urls.forEach((url) => {
                window.open(`${url}${additionalParams}`, '_blank');
            });
        },

        /**
         * Downloads a string as a named csv file
         *
         * @param {String} filename The name of the file you want to present to the user
         * @param {String} text The "CSV" contents of the file
         */
        downloadAsCSVFile(filename, text) {
            const element = document.createElement('a');
            element.setAttribute('href', `data:application/octet-stream;charset=utf-8,${encodeURIComponent(text)}`);
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },

        /**
         * Update the map layers to toggle Accessibility Mode on/off.
         */
        async setAccessibilityMode() {
            this.loadingMessage = 'Toggling accessibility mode';
            // set the text functions and text
            if (this.accessibilityMode) {
                this.damsLayer = this.damsLayer.clone({ visible: false });
                this.waterfallsLayer = this.waterfallsLayer.clone({ visible: false });
                this.fishwaysLayer = this.fishwaysLayer.clone({ visible: false });
                // this.crossingsLayer = this.crossingsLayer.clone({ visible: false });
            } else {
                this.damsA11yLayer = this.damsA11yLayer.clone({ visible: false });
                this.waterfallsA11yLayer = this.waterfallsA11yLayer.clone({ visible: false });
                this.fishwaysA11yLayer = this.fishwaysA11yLayer.clone({ visible: false });
                // this.crossingsA11yLayer = this.crossingsA11yLayer.clone({ visible: false });
            }
            this.map.setLayoutProperty('cabd-modelled-crossings', 'visibility', this.accessibilityMode ? 'none' : 'visible');
            this.map.setLayoutProperty('cabd-modelled-crossings-a11y', 'visibility', this.accessibilityMode ? 'visible' : 'none');
            this.map.setLayoutProperty('cabd-modelled-crossings-a11y-text', 'visibility', this.accessibilityMode ? 'visible' : 'none');

            await this.applyFilters();
        },

        /**
         * Open the filters modal. Ensure the download modal is closed.
         */
        toggleFiltersSidebar() {
            this.$refs.downloadSidebar.closeSidebar();
            this.$refs.filterSidebar.toggleSidebar();
            this.expandSidebar = this.$refs.filterSidebar.showSidebar;
        },

        /**
         * Open the filters modal. Ensure the download modal is closed.
         */
        closeFiltersSidebar() {
            this.$refs.filterSidebar.closeSidebar();
            this.expandSidebar = false;
        },

        /**
         * Open the download modal. Ensure the filters modal is closed.
         */
        toggleDownloadSidebar() {
            this.$refs.filterSidebar.closeSidebar();
            this.$refs.downloadSidebar.toggleSidebar();
            this.expandSidebar = this.$refs.downloadSidebar.showSidebar;
        },

        /**
         * Close the download modal.
         */
        closeDownloadSidebar() {
            this.$refs.downloadSidebar.closeSidebar();
            this.expandSidebar = false;
        },

        closeMainSidebar() {
            this.$refs.filterSidebar.closeSidebar();
            this.$refs.downloadSidebar.closeSidebar();
            this.expandSidebar = false;
        },

        /**
         * Build the attribute filters. The filterDefinitions provide the majority of the information for them, while
         * the featureTypeDefinitions provides actual values that we want to provide subfilter options for.
         *
         * For example, filterDefinitions has information about the dams filter. The filter type, label, map layers
         * filters should be applied to, and whether the filter is active. The subfilters are also included, with
         * label, type of subfilter, attributeName. The attributeName is used to look in featureTypeDefinitions and
         * find out what options the subfilter should have - so for the Dam Operating Status, it finds out what
         * options to have checkboxes for.
         */
        buildAttributeFilters() {
            // we do this once featureTypeDefinitions is defined and only one time to populate attributeFilters
            if (!isEmpty(this.featureTypeDefinitions) && isEmpty(this.attributeFilters)) {
                const filterDefinitions = this.$t('FILTER_DEFINITIONS');
                filterDefinitions.forEach((filter) => {
                    // for each filter, we go through the subfilters
                    const subfilters = filter.subfilters.map((subfilter) => {
                        // checkbox type subfilter
                        if (subfilter.type === 'checkbox') {
                            // find the attribute in featureTypeDefinitions for this subfilter
                            const attribute = this.findAttribute(filter.type, subfilter.attributeName);

                            // create filter values from attribute values
                            const values = attribute.values.map((value) => ({
                                ...value,
                                active: true,
                            }));

                            // return the subfilter with its values
                            return {
                                ...subfilter,
                                values,
                            };
                        }

                        // boolean type subfilter
                        if (subfilter.type === 'boolean') {
                            // add filter values to handle boolean value being true or false
                            return {
                                ...subfilter,
                                values: [
                                    {
                                        name: 'Yes',
                                        active: true,
                                    },
                                    {
                                        name: 'No',
                                        active: true,
                                    },
                                ],
                            };
                        }

                        // range type subfilter
                        if (subfilter.type === 'range') {
                            // find the attribute in featureTypeDefinitions for this subfilter
                            const attribute = this.findAttribute(filter.type, subfilter.attributeName);

                            // get the min/max values for the range from the attribute
                            const min = Math.floor(attribute.min_value);
                            const max = Math.ceil(attribute.max_value);

                            // return the subfilter with the range values
                            return {
                                ...subfilter,
                                range: [min, max],
                                // filteredRange is the v-model for the slider, so we need to set the initial value
                                filteredRange: [min, max],
                            };
                        }

                        // by default, just return the subfilter
                        return subfilter;
                    });

                    // add the filter with it's subfilters to attributeFilters
                    this.$set(this.attributeFilters, filter.type, {
                        ...filter,
                        subfilters,
                    });
                });

                // record the default attribute filters, to make it easy to reset the attribute filters
                this.defaultAttributeFilters = JSON.parse(JSON.stringify(this.attributeFilters));
            }
        },

        /**
         * Find an attribute for the current filter type in featureTypeDefinitions, using the given attribute id.
         *
         * @param {string} attributeId - the id of the attribute to find
         * @returns {object} - the attribute object
         */
        findAttribute(filterGroupType, attributeId) {
            return this.featureTypeDefinitions[filterGroupType].attributes.find(
                (attribute) => attribute.id === attributeId,
            );
        },

        /**
         * Reset the filters to the defaults values
         */
        resetFilters() {
            this.attributeFilters = JSON.parse(JSON.stringify(this.defaultAttributeFilters));
            this.locationFilters = {};
        },

        /**
         * Apply the filters to the map features.
         *
         * @param {Object} [locationFilters = null] - location filters to be applied
         * @param {Object} [attributeFilters = null] - attribute filters to be applied
         */
        async applyFilters(attributeFilters = null, locationFilters = null, pan = true) {
            if (this.loadingMessage === true || this.loadingMessage === false) {
                this.loadingMessage = this.$t('APPLYING_FILTERS');
            }
            setTimeout(async () => {
                if (locationFilters) {
                    this.locationFilters = JSON.parse(JSON.stringify(locationFilters));
                }

                if (attributeFilters) {
                    this.attributeFilters = JSON.parse(JSON.stringify(attributeFilters));
                }

                let locationExpression;
                if (this.locationFilters?.selectedProvinces?.length || this.locationFilters?.selectedWatersheds?.length) {
                    // what if we want to have watersheds and provinces at the same time? Not supported yet!
                    const searchByProvince = this.locationFilters.locationType === 'province';

                    const featureAttribute = searchByProvince ? 'province_territory_code' : 'nhn_watershed_id';

                    const regions = searchByProvince
                        ? this.locationFilters.selectedProvinces
                        : this.locationFilters.selectedWatersheds;

                    // pull region values out, to include in the filter
                    const toInclude = regions.map((region) => region.value);
                    locationExpression = ['in', ['get', featureAttribute], ['literal', toInclude]];

                    this.damsFiltered = {
                        type: this.dams.type,
                        features: this.dams.features.filter((f) => toInclude.includes(f.properties[featureAttribute])),
                    };
                    this.waterfallsFiltered = {
                        type: this.waterfalls.type,
                        features: this.waterfalls.features.filter(
                            (f) => toInclude.includes(f.properties[featureAttribute]),
                        ),
                    };
                    this.fishwaysFiltered = {
                        type: this.fishways.type,
                        features: this.fishways.features.filter(
                            (f) => toInclude.includes(f.properties[featureAttribute]),
                        ),
                    };
                    this.crossingsFiltered = {
                        type: this.modelled_crossings.type,
                        features: this.modelled_crossings.features.filter(
                            (f) => toInclude.includes(f.properties[featureAttribute]),
                        ),
                    };
                    this.highlightNhnWorkUnits(toInclude);
                } else {
                    this.damsFiltered = {
                        type: this.dams.type,
                        features: this.dams.features,
                    };
                    this.waterfallsFiltered = {
                        type: this.waterfalls.type,
                        features: this.waterfalls.features,
                    };
                    this.fishwaysFiltered = {
                        type: this.fishways.type,
                        features: this.fishways.features,
                    };
                    // if no regions selected, pass empty array to clear any highlights
                    this.highlightNhnWorkUnits([]);
                }

                // Attribute Filters for deck properties
                if ('dams' in this.attributeFilters) {
                    // filter dams maybe?
                    if (!this.attributeFilters.dams.active) {
                        // faster operation than using js to filter out the points
                        this.damsLayer = this.damsLayer.clone({ visible: false });
                        this.damsA11yLayer = this.damsA11yLayer.clone({ visible: false });
                        this.damsFiltered = {
                            type: this.dams.type,
                            features: [],
                        }; // for csv export
                    } else {
                        this.damsFiltered = {
                            type: this.dams.type,
                            features: (this.filterDeckFeatures(
                                this.attributeFilters.dams.subfilters,
                                this.damsFiltered,
                            )),
                        };
                        if (!this.accessibilityMode) {
                            this.damsLayer = this.damsLayer.clone({ visible: true, data: this.damsFiltered });
                        } else {
                            this.damsA11yLayer = this.damsA11yLayer.clone({ visible: true, data: this.damsFiltered });
                        }
                    }
                }
                if ('waterfalls' in this.attributeFilters) {
                    if (!this.attributeFilters.waterfalls.active) {
                        this.waterfallsLayer = this.waterfallsLayer.clone({ visible: false });
                        this.waterfallsA11yLayer = this.waterfallsA11yLayer.clone({ visible: false });
                        this.waterfallsFiltered = {
                            type: this.waterfalls.type,
                            features: [],
                        };
                    } else {
                        this.waterfallsFiltered = {
                            type: this.waterfalls.type,
                            features: (this.filterDeckFeatures(
                                this.attributeFilters.waterfalls.subfilters,
                                this.waterfallsFiltered,
                            )),
                        };
                        if (!this.accessibilityMode) {
                            this.waterfallsLayer = this.waterfallsLayer.clone({
                                visible: true,
                                data: this.waterfallsFiltered,
                            });
                        } else {
                            this.waterfallsA11yLayer = this.waterfallsA11yLayer.clone({
                                visible: true,
                                data: this.waterfallsFiltered,
                            });
                        }
                    }
                }
                if ('fishways' in this.attributeFilters) {
                    if (!this.attributeFilters.fishways.active) {
                        this.fishwaysLayer = this.fishwaysLayer.clone({ visible: false });
                        this.fishwaysA11yLayer = this.fishwaysA11yLayer.clone({ visible: false });
                        this.fishwaysFiltered = {
                            type: this.fishways.type,
                            features: [],
                        };
                    } else {
                        this.fishwaysFiltered = {
                            type: this.fishways.type,
                            features: (this.filterDeckFeatures(
                                this.attributeFilters.fishways.subfilters,
                                this.fishwaysFiltered,
                            )),
                        };
                        if (!this.accessibilityMode) {
                            this.fishwaysLayer = this.fishwaysLayer.clone({
                                visible: true,
                                data: this.fishwaysFiltered,
                            });
                        } else {
                            this.fishwaysA11yLayer = this.fishwaysA11yLayer.clone({
                                visible: true,
                                data: this.fishwaysFiltered,
                            });
                        }
                    }
                }

                // this.filterMapboxFeatures(this.attributeFilters.flowpaths, 'flowpaths', locationExpression);
                // this.filterMapboxFeatures(this.attributeFilters.catchments, 'catchments', locationExpression);
                this.filterMapboxFeatures(this.attributeFilters['nhn-work-units'], 'nhn-work-units', locationExpression);

                if (this.locationFilters.selectedWatersheds.length) {
                    const boundingBox = [[180, 90], [-180, -90]];
                    this.locationFilters.selectedWatersheds.forEach((watershed) => {

                        // minimum x
                        boundingBox[0][0] = Math.min(watershed.bbox[0][0], boundingBox[0][0]);
                        // minimum y
                        boundingBox[0][1] = Math.min(watershed.bbox[0][1], boundingBox[0][1]);
                        // maximum x
                        boundingBox[1][0] = Math.max(watershed.bbox[1][0], boundingBox[1][0]);
                        // maximum y
                        boundingBox[1][1] = Math.max(watershed.bbox[1][1], boundingBox[1][1]);
                    });
                    if (pan) this.goToLocation({ boundingBox });
                }


                const modelFilter = ['all'];
                if (!this.attributeFilters.modelled_crossings.active) {
                    // This should never be true
                    modelFilter.push(['==', 'pikachu', 1])
                } else {
                    this.attributeFilters.modelled_crossings.subfilters.forEach(filter => {
                        const thisFilter = ['any'];
                        filter.values.forEach(subValue => {
                            if (subValue.active) {
                                if (subValue.value) {
                                    thisFilter.push(['==', filter.attributeName, subValue.value]);
                                } else {
                                    thisFilter.push(['==', filter.attributeName, subValue.name === 'Yes']);
                                }
                            }
                        });
                        if (thisFilter.length > 1) modelFilter.push(thisFilter);
                    });

                    const searchByProvince = this.locationFilters.locationType === 'province';
                    if (this.locationFilters?.selectedProvinces?.length && searchByProvince) {
                        const provinceFilter = ['any'];
                        this.locationFilters?.selectedProvinces.forEach(province => {
                            provinceFilter.push(['==', 'province_territory_code', province.value])
                        })
                        if (provinceFilter.length > 1) modelFilter.push(provinceFilter);
                    } else if (this.locationFilters?.selectedWatersheds?.length) {
                        const waterFilter = ['any'];
                        this.locationFilters?.selectedWatersheds.forEach(watershed => {
                            waterFilter.push(['==', 'nhn_watershed_id', watershed.value])
                        })
                        if (waterFilter.length > 1) modelFilter.push(waterFilter);
                    }
                }

                if (this.accessibilityMode) {
                    this.map.setFilter('cabd-modelled-crossings-a11y', modelFilter);
                    this.map.setFilter('cabd-modelled-crossings-a11y-text', modelFilter);
                } else {
                    this.map.setFilter('cabd-modelled-crossings', modelFilter);
                }

                this.updateBarrierPointLayers();
                this.loadingMessage = false;
            }, 25);
            // set data copies of filters. We do the JSON stringify/parse to avoid issues with reactivity.
        },

        applyNhn() {
            this.attributeFilters['nhn-work-units'].active = true;
            this.attributeFilters = JSON.parse(JSON.stringify(this.attributeFilters));
            this.filterMapboxFeatures(this.attributeFilters['nhn-work-units'], 'nhn-work-units', null);
        },

        filterMapboxFeatures(filter, key, locationExpression) {
            // build basic expression to filter by feature type
            const expression = ['all', ['==', ['get', 'feature_type'], key]];
            if (locationExpression) {
                // add locationExpression if there is one
                expression.push(locationExpression);
            }

            // filters can have multiple layers that filter expression needs to be applied to
            filter.layers.forEach((layer) => {
                // we go through each layer and set the filter expression on the layer
                if (filter.active && filter.subfilters.length) {
                    // check that filter is active and that is has subfilters. If no subfilters, the code below
                    // will show/hide the layer appropriately.
                    this.map.setFilter(layer, expression);
                }

                const mapLayer = this.map.getLayer(layer);
                if (mapLayer) {
                    const opacity = filter?.opacity ? filter.opacity : 1;
                    if (mapLayer.type === 'fill') {
                        this.map.setPaintProperty(layer, 'fill-opacity', filter.active ? opacity : 0);
                    }
                    if (mapLayer.type === 'line') {
                        this.map.setPaintProperty(layer, 'line-opacity', filter.active ? opacity : 0);
                    }
                }
            });
        },

        /**
         * Takes a set of well known subfilters and applies them to a geojson feature set. It returns
         * an array of the features with the subfilters applied.
         * Look at only inactive values because that tends to be faster!
         * @param {Array} subfilters An array of well defined objects.
         * @param {Object} unfilteredFeatures A geojson Feature Set with a "features" property
         *
         * @return {Array} An array of features, filtered as per the subfilters.
         */
        filterDeckFeatures(subfilters, unfilteredFeatures) {
            let { features } = unfilteredFeatures;
            subfilters.forEach((sf) => {
                // are we checking an array of values?
                if (sf.type === 'checkbox') {
                    const inactiveValues = sf.values.filter((x) => !x.active).map((x) => x.value);
                    if (inactiveValues.length) {
                        features = features.filter(
                            (f) => !(inactiveValues.includes(f.properties[sf.attributeName])),
                        );
                    }
                } else if (sf.type === 'range') {
                    // between the values
                    if (sf.filteredRange[0] !== sf.range[0] || sf.filteredRange[1] !== sf.range[1]) {
                        // apply filtered range
                        // if the attribute is null, ignore this filter?
                        features = features.filter(
                            (f) => (f.properties[sf.attributeName] >= sf.filteredRange[0]
                                && f.properties[sf.attributeName] <= sf.filteredRange[1]),
                        );
                    }
                } else if (sf.type === 'boolean') {
                    // if both are checked we don't need to apply anything, we want all
                    if (!sf.values[0].active || !sf.values[1].active) {
                        // check for features that have a non-null value for the attribute
                        // sf.values[0].active means "has non-null attribute"
                        // sf.values[1].active means "has null attribute"
                        // with the addition of network use, booleans must be accounted for
                        if (sf.values[0].active) {
                            features = features.filter(
                                (f) => (![null, false].includes(f.properties[sf.attributeName])),
                            );
                        } else {
                            features = features.filter(
                                (f) => ([null, false].includes(f.properties[sf.attributeName])),
                            );
                        }
                    }
                }
            });
            return features;
        },

        /**
         * Include or exclude a NHN Work Unit from the location filter. If the work unit is not in the existing
         * location filters, add it. If it is in the existing location filters, remove it.
         *
         * @param {object} workUnitInfo - information of the work unit to include/exclude
         */
        toggleNhnWorkUnitFilter(workUnitInfo) {
            // find out if work unit is already in location filters
            const workUnitIndex = this.locationFilters.selectedWatersheds.findIndex(
                (region) => region.value === workUnitInfo.id,
            );

            if (workUnitIndex === -1) {
                // add to location filters
                const toAdd = {
                    name: workUnitInfo[`name_${this.$i18n.locale}`],
                    value: workUnitInfo.id,
                    bbox: [[workUnitInfo.minx, workUnitInfo.miny], [workUnitInfo.maxx, workUnitInfo.maxy]],
                };

                this.locationFilters.selectedWatersheds.push(toAdd);
            } else if (this.locationFilters.locationType === 'watershed') {
                // we only want to remove the workUnit if we are currently filtering for watersheds. Otherwise the user
                // could click on a watershed, and if it was previously selected, it would be removed.
                this.locationFilters.selectedWatersheds.splice(workUnitIndex, 1);
            }

            // switch filter type to watershed
            this.locationFilters.locationType = 'watershed';

            // we updated the highlights nhn layers
            const regions = this.locationFilters.selectedWatersheds;
            const toInclude = regions.map((region) => region.value);
            this.highlightNhnWorkUnits(toInclude);
        },

        /**
         * Highlight the work units referenced in the given array.
         *
         * @param {Array} workUnitsToHighlight - work units to highlight
         */
        highlightNhnWorkUnits(workUnitsToHighlight) {
            const filter = ['in', ['get', 'id'], ['literal', workUnitsToHighlight]];
            this.map.setFilter(nhnWorkUnitLayers.highlightLayer.id, filter); // highlight is still just an outline layer
            this.map.setFilter(nhnWorkUnitLayers.outlineLayer.id, ['!', filter]);
        },
    },
};
</script>

<style lang="scss">
.map-view.page {
    position: relative;

    .cwf-logo {
        width: 12rem;
        position: absolute;
        left: 1.5rem;
        bottom: 1.5rem;
        z-index: 50;

        p {
            color: white;
            font-size: 9pt;
            margin-bottom: 1em;
            padding-left: 1em;
        }
    }

    .mapboxgl-ctrl-bottom-right {
        // show attribution above cwf logo when opened on small screens
        z-index: 11;
    }

    .sidebar {
        position: absolute;
        top: 0;
        left: 0;
    }

    #map-container {
        width: 100vw;
        height: 100vh;
        color: $primary;
        background-color: #eee;

        .sources-loading {
            position: absolute;
            width: 100vw;
            height: 100vh;
            background: rgba(255, 255, 255, 0.7);
            top: 0;
            left: 0;
            text-align: center;
            z-index: 40;

            .loading-text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 2rem;

                .loader {
                    display: inline-block;
                    position: absolute;
                    top: 20%;
                    margin-left: 15px;
                    border-top-color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        .mapboxgl-ctrl-attrib-inner {
            height: 1.25rem !important;

            a {
                outline: none !important;
                padding: 2px 0 1px 1px;
                border: transparent solid 2px !important;

                &:focus-visible {
                    border: $element-focus solid 2px !important;
                }
            }
        }

        *:focus-visible {
            outline: $element-focus solid 2px !important;
        }
    }
}
</style>
