<template>
  <div
    role="list"
    class="layer-list"
  >
    <hr>
    <b-loading
      v-if="!(layers && layers.length)"
      active
      :is-full-page="false"
      class="loading-indicator"
    />
    <layer-list-item
      v-for="(layer, index) in layers"
      :key="layer.label"
      :layer="layer"
      :layer-index-array="[index]"
      class="layer"
      role="listitem"
      :base-tab-index="201 + (index * 20)"
      :aria-label="`${layer.label} Map Layer`"
      :accessibility-mode="accessibilityModeActive"
      @layer-toggled="toggleLayerVisibility"
    />
  </div>
</template>

<script>
import LayerListItem from '@/components/LayerListItem.vue';

export default {
    name: 'LayerList',

    components: {
        LayerListItem,
    },

    props: {
        attributeFilters: {
            type: Object,
            required: true,
        },
        accessibilityModeActive: {
            type: Boolean,
            required: true,
        },
    },

    data: () => ({
        attributeFiltersLocal: {},
        layers: [],
    }),

    watch: {
        attributeFilters() {
            this.attributeFiltersLocal = JSON.parse(JSON.stringify(this.attributeFilters));
        },

        attributeFiltersLocal: {
            deep: true,
            handler: function handler() {
                const layers = [];

                // convert attribute filters into layers, for use in layer list
                Object.keys(this.attributeFiltersLocal).forEach((key, index) => {
                    const filterGroup = this.attributeFiltersLocal[key];

                    // need to add the expanded and children props
                    const layer = {
                        ...filterGroup,
                        expanded: this.layers && this.layers[index] && this.layers[index].expanded,
                        children: [],
                    };

                    // find the passability status subfilter for this feature type and use its values to make children
                    // for the layer
                    const passabilityStatus = filterGroup.subfilters.find((f) => f.attributeName === 'passability_status_code');
                    if (passabilityStatus) {
                        passabilityStatus.values.forEach((value) => {
                            layer.children.push({
                                name: value.name,
                                label: value.name,
                                active: value.active,
                            });
                        });
                    }

                    layers.push(layer);
                });

                this.layers = layers;
            },
        },
    },

    methods: {
    /**
         * Toggle layer visibility.
         *
         * @param {array} layerIndexArray - array of indexes that reference layer to toggle
         * @param {boolean} active - whether or not the layer should be active
         */
        toggleLayerVisibility(layerIndexArray, active) {
            // get the key for the feature level filter
            const filterKey = Object.keys(this.attributeFiltersLocal)[layerIndexArray[0]];

            if (layerIndexArray.length === 1) {
                // if we only have one index, we are toggling the feature type
                this.attributeFiltersLocal[filterKey].active = active;
            } else {
                // if we have more than one filter, we are toggling the passability status for the feature type
                // get passabilityStatus attribute for feature and set active appropriately
                const passabilityStatus = this.attributeFiltersLocal[filterKey].subfilters.find((f) => f.attributeName === 'passability_status_code');
                passabilityStatus.values[layerIndexArray[1]].active = active;
            }

            this.$emit('layers-updated', this.attributeFiltersLocal);
        },
    },
};
</script>

<style lang="scss">
.layer-list {
    position: relative;
    display: grid;
    cursor: pointer;
    min-height: 6rem;

    .loading-indicator .loading-background {
        background-color: transparent;
    }
}
</style>
