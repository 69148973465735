import Vue from 'vue';
import Router from 'vue-router';
import MapView from '@/views/MapView.vue';
import App from './App.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.VUE_BASE_URL,
    routes: [
        {
            path: '/',
            redirect: 'en',
        },
        {
            path: '/:locale',
            // path: '/',
            component: App,
            children: [
                {
                    path: '',
                    name: 'mapview',
                    component: MapView,
                },
                {
                    path: 'data-sources',
                    name: 'data-sources',
                    // lazy loading route: import component NOT required
                    component: () => import(/* webpackChunkName: "datasources" */ '@/views/DataSources.vue'),
                },
            ],
        },
    ],
});

export default router;
